import { render, staticRenderFns } from "./user-info.vue?vue&type=template&id=1154a8a3&scoped=true&"
import script from "./user-info.js?vue&type=script&defer=true&lang=js&"
export * from "./user-info.js?vue&type=script&defer=true&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1154a8a3",
  null
  
)

export default component.exports