<template>
  <div class="panel border border-secondary">
    <div class="header">
      AWS Data Sets
    </div>

    <div class="card">
      <div class="panel border border-secondary">

        <div class="card-body">

          <b-modal ref="wait-modal" title="Please Wait">
            <p class="my-4">{{ status }}</p>
          </b-modal>

          <div class="row row-cols-4 row-cols-md-4 g-4">
            <div class="col">
              <b-button style="font-size: smaller" class="m-1" v-on:click="retrieveDataSetList">
                Retrieve Dataset List From AWS
              </b-button>
            </div>
            <div class="col">
              <b-form-input v-model="movementName" placeholder="regexp for movement name"></b-form-input>
              <TaggerWidget ref="tagWidget" tagType="query" placeholder="Filter on tags"/>
            </div>
            <div class="col-2">
              &nbsp;
            </div>
          </div>
          <br>
          <b-form-group>
            <b-button style="font-size:smaller" class="m-1" :disabled="!isSelected" v-on:click="loadAws">
              Load To Visualization
            </b-button>
            <b-button style="font-size: smaller" class="m-1" :disabled="!isSelected" v-on:click="download">
              Save Dataset to File
            </b-button>
            <b-button style="font-size: smaller" class="m-1" :disabled="!isSelected" v-on:click="downloadToCsv">
              Export Dataset to CSV File
            </b-button>
            <b-form-checkbox id="checkbox-recreateanalytics" v-model="reload" name="checkbox-recreateanalytics">
              Recreate analytics
            </b-form-checkbox>
          </b-form-group>
          <br>
          <b-button style="font-size: smaller" class="m-1" :disabled="status !== 'ready'" v-on:click="toggleNote">
            add/update note
          </b-button>

          <b-table :items="dataSetList" :fields="fields" sort-by="processedAt" :sort-desc="true" :striped="true" :small="true" :hover="true" sticky-header="500px"
            table-variant="light" selected-variant="info" responsive="sm" selectable ref="selectableTable"
            @row-selected="clicked" select-mode="single"></b-table>

        </div>
        <div v-if="noteEditVisible" class="card-footer">
          <b-form-input v-model="selected.note" :placeholder="selected.note"></b-form-input>
          <b-button style="font-size: smaller" class="m-1" v-on:click="updateNote">
            Update
          </b-button>
        </div>
        <div class="card m-1">
          <div v-b-toggle.collapse-setdetails class="card-header"><small>Details...</small></div>
          <b-collapse id="collapse-setdetails">
            <pre>{{ details }}</pre>
          </b-collapse>
        </div>
        <div class="card m-1">          
          <div v-b-toggle.collapse-report class="card-header">
            <small>Report...</small>
          </div>          
          <b-collapse id="collapse-report">
            <b-form>
              <div class="form-row align-items-center">
                <div class="col-auto">
                  <b-button style="font-size: smaller" class="m-1" :disabled="!isSelected" v-on:click="generateReport">
                    Generate Report
                  </b-button>
                </div>
                <div class="col-auto">
                  <b-form-checkbox :disabled="!isSelected" v-model="overrideMovementName">
                    Override Movement Name
                  </b-form-checkbox>
                </div>
                <div class="col-auto">
                  <b-form inline v-show="overrideMovementName">
                    <label :for="`type-${reportMovementName}`" style="font-size: smaller">Movement Name:</label>
                    <b-form-input size="sm" v-model="reportMovementName"></b-form-input>
                  </b-form>
                </div>
              </div>
            </b-form>
            <pre>{{ report }}</pre>
          </b-collapse>
        </div>
        <div class="card-footer">
          <small>{{ status }}</small>
        </div>
      </div>

      <div class="header">
        Local Data Sets
      </div>
      <div class="card">
        <div class="panel border border-secondary">
          <div class="card-body">
            <FilePicker v-bind:currentFile="currentLocalFile" appData="localFile" @filePicked="filePicked"></FilePicker>
          </div>
        </div>
      </div>
      <div class="row row-cols-1 row-cols-md-2 g-4">
        <div>
          <b-button style="font-size: smaller" class="m-1" v-on:click="deleteDataset">Delete?</b-button>
          <br>
          <TaggerWidget :tagType="tagType" />
        </div>
        <div class="col">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title">Comments</h5>
              <div class="card-footer">
                <b-form-input v-model="comment" :placeholder="comment"></b-form-input>
                <b-button style="font-size: smaller" class="m-1" v-on:click="addComment">Add Comment</b-button>
                <pre>{{ comments }}</pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Auth} from '@aws-amplify/auth';
import {datasetToCsv, toDataSet} from '@motusi/sharedjs';
import FilePicker from './file-picker.vue';
import TaggerWidget from './Tagger.vue';
import moment from 'moment';
import LZString from 'lz-string';
import cryptoUtils from '../../util/crypto';

export default {
  name: 'DataSets',
  components: {
    FilePicker,
    TaggerWidget,
  },
  computed: {
    dataSetList() {
      return this.$store.getters.datasetlist;
    },
    dataSetSelected() {
      return this.$store.getters.datasetSelected;
    },
  },
  data() {
    return {
      status: 'init',
      details: '',
      noteEditVisible: false,
      isSelected: false,
      selected: {},
      listSelected: 0,
      comments: [],
      movementName: null,
      comment: '',
      currentLocalFile: 'none',
      reload: true,
      report: '',
      overrideMovementName: false,
      reportMovementName: null,
      fields: [
        {key: 'path', sortable: true},
        {key: 'id', sortable: true},
        {key: 'duration', sortable: true},
        {key: 'processedAt', sortable: true},
        {key: 'note', sortable: true},
        {key: 'name', sortable: true},
      ],
      tagType: 'dataset',
    }
  },
  async mounted() {
    cryptoUtils.generateKeyPair();
    if (this.$route.query.load === 'true') {
      if (this.$route.query.datasetid) {
        this.selected.id = this.$route.query.datasetid;
        this.loadAws();
        return;
      }
    }

    if (this.dataSetList.length == 0) {
      await this.retrieveDataSetList();
    } else {
      if (this.dataSetSelected) {
        this.clicked([this.dataSetSelected]);
        this.isSelected = true;
        this.$refs.selectableTable.selectRow(
          this.$refs.selectableTable.items.findIndex(e => e.id === this.dataSetSelected.id)
        );
      }
    }
  },
  methods: {
    async clicked(row) {
      if (!row[0]) return;
      this.$root.$emit("updateTags", {
        datasetId: null,
        tags: [],
      });
      this.$store.commit('datasetSelected', row[0]);
      this.selected = row[0];
      if (this.selected) {
        this.isSelected = true;
        this.status = 'ready';
        const comments = await this.$API.getDatasetComments(this.selected.id);
        this.comments = comments.map(e => e.comment).join("\n");
        const tags = await this.$API.getDatasetTags(this.selected.id);
        this.$root.$emit("updateTags", {
          datasetId: this.selected.id,
          tags: tags,
        });
      } else {
        this.isSelected = false;
        this.status = 'no file selected';
      }
      this.changed();
    },
    async changed() {
      this.details = JSON.stringify(
        this.selected,
        null,
        2
      );
    },
    async retrieveDataSetList() {
      const tags = this.$refs['tagWidget'].tagify.value;
      const tagIds = tags.length && {tagId: tags.map(e => e.id).join(',')} || {};
      console.log('TAG', tags);
      this.status = 'retrieving datasets';
      const filter = Object.assign(
        {
          sort: '-processedAt',
        },
        this.movementName && {movementName: this.movementName},
        tagIds,
      );
      const initial = await this.$API.paginateRequest(this.$API.searchDatasets.bind(null, filter), {page: 1, limit: 40}, 500);
      const list = initial.map(e => ({
        ...e,
        path: `${e.programName}/${e.movementName}`,
        duration: moment(e.file?.endTime).diff(moment(e.file?.startTime)) / 1000,
        name: `${e.creator.firstName} ${e.creator.lastName}`,
      }));
      this.$store.commit('datasetlist', list);
      this.status = 'ready';
      this.changed();
    },
    async fetchDataSet(doLoad) {
      //console.log('fetchDataSet', doLoad);
      this.status = `fetching ${this.selected.id}`;
      this.$refs['wait-modal'].show();

      try {
        //console.log('getting data set id', this.selected.id);
        const dataset = await this.$API.downloadDataset(this.selected.id);

        if (this.reload) {
          // tell the dataset loader to reload/recalculate from raw quaternions
          dataset.dataset.reload = true;
          doLoad = true;
        }

        if (doLoad) {
          this.status = `loading frame cache`;
          console.log('loadingAWS loadDataSet version:', dataset.dataset.version, 'reload', this.reload);
          this.$AnalyticsEngine.loadDataSet(dataset.dataset);
          // Propagate metadata from stored dataset into loaded dataset
          const meta = {
            label: dataset.dataset.label,
            memberId: dataset.dataset.memberId,
            movementId: dataset.dataset.movementId,
            timelineTags: dataset.dataset.timelineTags,
          };
          const str = await toDataSet(this.$AnalyticsEngine.frameCache, undefined, meta);
          dataset.dataset = JSON.parse(str);
        }

        this.$refs['wait-modal'].hide();

        const user = await Auth.currentAuthenticatedUser();
        this.selected.email = user.attributes.email;
        return {dataset: dataset.dataset, info: this.selected};
      } catch (e) {
        const err = `get-datasets-for-movement-error ${e} ${e.response}`;
        console.log(err);
        this.status = err;
        this.$refs.status.innerHTML = err;
      }
    },

    async download() {
      const result = await this.fetchDataSet(this.reload);
      const name = this.getFileName(null, result.dataset.startTime, 'json');

      // Save to local file
      const a = document.createElement('a');
      const blob = new Blob([JSON.stringify(result.dataset, null, 2)], {
        type: 'application/json',
      });

      a.href = URL.createObjectURL(blob);
      a.download = name;
      a.style = 'display: none';
      a.click();
      URL.revokeObjectURL(a.href);
    },
    async downloadToCsv() {
      const result = await this.fetchDataSet(this.reload);
      const name = this.getFileName(null, result.dataset.startTime, 'csv');
      const csv = datasetToCsv(result.dataset);

      // Save to local file
      const a = document.createElement('a');
      const blob = new Blob([csv], {
        type: 'application/json',
      });

      a.href = URL.createObjectURL(blob);
      a.download = name;
      a.style = 'display: none';
      a.click();
      URL.revokeObjectURL(a.href);
    },
    async generateReport() {
      this.report = '';
      const dataset = await this.fetchDataSet(this.reload);
      // If user wants to override the dataset movement name
      if (this.overrideMovementName && 
          this.reportMovementName && 
          this.reportMovementName !== "") {
        dataset.dataset.movementName = this.reportMovementName;
      }
      const report = this.$AnalyticsEngine.generateReportForDataset(dataset.dataset);
      this.report = JSON.stringify(
        report,
        null,
        2
      );
    },

    getFileName(name, startTime, ext) {
      let constructedName = name ? name : '';

      if (!constructedName.length === 0 && this.selected.note && this.selected.note.length > 0) {
        constructedName = '' + this.selected.note.split(' ').join('') + `.${ext}`;
      }
      if (constructedName.length === 0) {
        constructedName = `${startTime}.${ext}`;
      }
      return constructedName;
    },
    async loadAws() {
      //console.log('loadAWS');
      this.$refs['wait-modal'].show();
      //this.selected = undefined;
      this.$emit('dataLoading');
      const result = await this.fetchDataSet(true);
      this.status = 'loading frame cache';

      const filename = this.getFileName(null, result.dataset.startTime, 'json');
      const asString = JSON.stringify(result.dataset);
      const recordingSource = JSON.stringify(
        {...result.info, size: asString.length, filename},
        null,
        2
      );
      localStorage.setItem('source', recordingSource);
      localStorage.setItem('recordingLZ', LZString.compress(asString));

      const recordingSourceObj = JSON.parse(recordingSource);
      this.$store.commit('dataSetLoaded', recordingSourceObj);
      this.$refs['wait-modal'].hide();
      this.$emit('dataLoaded', recordingSourceObj);
    },
    toggleNote() {
      this.noteEditVisible = !this.noteEditVisible;
    },
    async updateNote() {
      await this.$API.updateDataset(this.selected.id, this.selected.note);
      await this.retrieveDataSetList();
      this.noteEditVisible = false;
      this.changed();
    },
    async addComment() {
      await this.$API.createDatasetComment(this.selected.id, this.comment);
      this.comment = '';
      this.changed();
      this.clicked(this.dataSetSelected);
    },
    async deleteDataset() {
      const confirm = await this.$bvModal.msgBoxConfirm('Are ya sure?!');
      if (confirm) {
        console.log('deleting dataset', this.selected.id);
        this.$API.deleteDataset(this.dataSetSelected.id);
        this.$store.commit('datasetlist', this.dataSetList.filter(e => e.id !== this.dataSetSelected.id));
        this.$store.commit('datasetSelected', null);
        this.selected = undefined;
        this.changed();
      }
    },
    async filePicked() {
      //this.selected = undefined;
      this.$emit('dataLoading');
      this.status = 'loading frame cache from local file';

      this.currentLocalFile = this.$AppData.localFile.fileName;
      const datasetEnvelope = JSON.parse(this.$AppData.localFile.contents);
      const dataset = datasetEnvelope.dataset;
      const asString = JSON.stringify(dataset);
      const filename = this.getFileName(this.currentLocalFile, dataset.startTime, 'json');
      if (this.reload) {
        // tell the dataset loader to reload/recalculate from raw quaternions
        dataset.reload = true;
      }

      this.status = `loading frame cache`;
      //console.log('loading local file version:', dataset.version, 'reload', this.reload);
      this.$AnalyticsEngine.loadDataSet(dataset);

      const date = new Date(dataset.startTime);

      const info = {
        id: dataset.id ? dataset.id : `local-file: ${this.currentLocalFile}`,
        duration: dataset.duration,
        endTime: dataset.endTime,
        frameCount: dataset.frameCount,
        label: dataset.label,
        memberId: dataset.memberId,
        mobileAppId: dataset.mobileAppId,
        movementId: dataset.movementId,
        movementName: dataset.movementName,
        sharedJSVersion: dataset.sharedJSVersion,
        startTime: dataset.startTime,
        localTime: `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`,
        timelineTags: dataset.timelineTags,
        version: dataset.version,
      };

      const recordingSource = JSON.stringify(
        {...info, size: asString.length, filename},
        null,
        2
      );
      localStorage.setItem('source', recordingSource);
      localStorage.setItem('recordingLZ', LZString.compress(asString));

      const recordingSourceObj = JSON.parse(recordingSource);
      this.$store.commit('dataSetLoaded', recordingSourceObj);
      this.$emit('dataLoaded', recordingSourceObj);
    },
  },
};

</script>
