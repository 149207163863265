import {ProfilingTimer} from "./profiling-timer";
import * as THREE from 'three';
import {pointToThreeJs, quaternionToThreeJs} from "./coordinate-systems";
import {
  getPartModelData,
  FrameType,
  ModelStateElement,
  parseTuple,
} from "@motusi/sharedjs";

const CORRECTIONS_INIT = "init";
const CORRECTIONS_SET = "set";
const CORRECTIONS_CLEARED = "cleared";


const timers = new ProfilingTimer();
timers.addMetric({
  name: "fps",
  reportInterval: 1.5,
  report: (ctx) => {
    //dom.timings.fps = (ctx.counter / ((ctx.last - ctx.first) / 1000)).toFixed();
    ctx.first = 0;
    ctx.counter = 0;
    //dom.timings.update();
  },
});

timers.addMetric({
  name: "topFps",
  reportInterval: 1.5,
  report: (ctx) => {
    //dom.timings.topFps = (ctx.counter /((ctx.last - ctx.first) / 1000)).toFixed();
    ctx.first = 0;
    ctx.counter = 0;
    //dom.timings.update();
  },
});

timers.addMetric({
  name: "bottomFps",
  reportInterval: 1.5,
  report: (ctx) => {
    //dom.timings.bottomFps = (ctx.counter /((ctx.last - ctx.first) / 1000)).toFixed();
    ctx.first = 0;
    ctx.counter = 0;
    //dom.timings.update();
  },
});

timers.addMetric({
  name: "actual",
  reportInterval: 0.25,
  report: (/*ctx*/) => {
    //const systemElapsed = Date.now() - ctx.playStart;
    //const recordingElapsed = ctx.currentFrame - ctx.firstFrame;
    //dom.timings.recordingElapsed = (recordingElapsed / 1000).toFixed(1);
    //dom.timings.elapsedTime = (systemElapsed / 1000).toFixed(1);
    //dom.timings.update();
  },
});

export default class Rig {
  constructor() {
    console.log("Rig Constructed");
    this.corrections = CORRECTIONS_INIT;
    this.currentFrame = 0;
  }

  init(vue) {
    this.vue = vue;
  }

  initScene(scene) {
    //console.log('Rig.initScene');
    this.scene = scene;

    this.vue.$AnalyticsEngine.body.update();
    this.vue.$AnalyticsEngine.body.partMap.forEach((part) => {
      this.scene.add(this.vue.$AnalyticsEngine.body.kchain[part]);
    });

    this.resetAvatar();
    this.vue.$root.$on('MotionFrame', this.motionFrameListener.bind(this));
    this.vue.$root.$on('AnalyticsFrame', this.analyticsListener.bind(this));
    //console.log('Rig.initScene complete', FrameCacheEvent);
  }

  clearQInitials() {
    //console.log('Rig.clearQInitials');
    this.corrections = CORRECTIONS_CLEARED; // don't set correction automatically
    this.vue.$AnalyticsEngine.body.clearQInitials();
    this.resetAvatar();
  }

  setQInitials() {
    const rotations = this.vue.$AnalyticsEngine.body.getQSensors();
    if (rotations[0][0] == 1) {
      console.log("why this!!!!", rotations.hip);
      // return;
    }

    this.corrections = CORRECTIONS_SET;
    this.vue.$AnalyticsEngine.setAlignment(rotations);
    this.vue.$AnalyticsEngine.body.update();
    this.resetAvatar();
  }

  resetAvatar() {
    if (!this.scene) {
      return;
    }

    const modelFrame = this.vue.$AnalyticsEngine.getModelStateFrame(0);

    this.motionFrameListener(modelFrame);
  }

  saveMovie() {
    this.scene.saveMovie();
  }

  motionFrameListener(modelFrame) {
    if (!(this.scene && modelFrame && modelFrame.type == FrameType.ModelStateFrame)) {
      return;
    }

    if (0 == timers.metric.fps.first) {
      timers.metric.fps.first = modelFrame.timestamp;
    }
    timers.metric.fps.last = modelFrame.timestamp;
    timers.metric.fps.increment();
    
    if (modelFrame.index === 0) {
      timers.metric.actual.playStart = Date.now();
      timers.metric.actual.firstFrame = modelFrame.timestamp;
    }
    timers.metric.actual.currentFrame = modelFrame.timestamp;
    

    this.vue.$AnalyticsEngine.body.partMap.forEach((part, index) => {
      // Get position and orientation of part
      const qModel = getPartModelData(modelFrame, index, ModelStateElement.QMODEL);
      const pCenter = getPartModelData(modelFrame, index, ModelStateElement.CENTER);

      // Convert to ThreeJS coordinate system
      const rotation = new THREE.Quaternion().fromArray(quaternionToThreeJs(qModel));
      const center = new THREE.Vector3().fromArray(pointToThreeJs(pCenter));

      this.scene.meshUpdate(part, center, rotation);
    });

    const leftFoot = this.vue.$AnalyticsEngine.body.kchain['left-lower-leg'].distal;
    const rightFoot = this.vue.$AnalyticsEngine.body.kchain['right-lower-leg'].distal;
    this.scene.storeFeetPosition(leftFoot, rightFoot);
  }

  enableAnalytics(config) {
    this.vue.$AnalyticsEngine.setActive(config);
  }

  analyticsListener(envelope) {
    if (!envelope || !envelope.tuple) {
      return;
    }

    const com = [
      parseTuple("com_point_x", envelope.tuple),
      parseTuple("com_point_y", envelope.tuple),
      parseTuple("com_point_z", envelope.tuple),
    ];
    this.scene.addCoM(pointToThreeJs(com), 1);

    const grf = {
      left: [
        parseTuple("ground_force_x_left", envelope.tuple),
        parseTuple("ground_force_y_left", envelope.tuple),
        parseTuple("ground_force_z_left", envelope.tuple),
      ],
      right: [
        parseTuple("ground_force_x_right", envelope.tuple),
        parseTuple("ground_force_y_right", envelope.tuple),
        parseTuple("ground_force_z_right", envelope.tuple),
      ],
    };

    this.scene.addGroundReactionForces(grf.left, grf.right);

    const grfAccel = {
      left: [
        parseTuple("accel_ground_force_x_left", envelope.tuple),
        parseTuple("accel_ground_force_y_left", envelope.tuple),
        parseTuple("accel_ground_force_z_left", envelope.tuple),
      ],
      right: [
        parseTuple("accel_ground_force_x_right", envelope.tuple),
        parseTuple("accel_ground_force_y_right", envelope.tuple),
        parseTuple("accel_ground_force_z_right", envelope.tuple),
      ],
    };

    this.scene.addGroundReactionForcesAccel(grfAccel.left, grfAccel.right);
  }
}
