<template>
  <div class="panel border border-secondary">
    <div v-b-toggle.collapse-analyticsselector class="header">
      Analytics Selector
    </div>

    <b-collapse id="collapse-analyticsselector">
      <b-button
        style="font-size: smaller"
        size="sm"
        class="m-1"
        v-on:click="createCSV"
        >Create CSV</b-button
      >
      <b-form-group>
        <b-row>
          <b-col class="col">
            <b-form-checkbox-group
              size="sm"
              v-model="selected"
              @change="changed"
            >
              <b-form-checkbox
                v-for="option in options.slice(0, options.length / 2 + 1)"
                :id="option.text"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
          <b-col class="col">
            <b-form-checkbox-group v-model="selected" @change="changed">
              <b-form-checkbox
                v-for="option in options.slice(options.length / 2 + 1)"
                :key="option.value"
                :value="option.value"
              >
                {{ option.text }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-col>
        </b-row>
      </b-form-group>
    </b-collapse>
  </div>
</template>

<style>
.custom-control-label {
  width: 300px;
  margin-top: 0;
}
</style>

<script>

export default {
  name: "DataTablePanel",
  props: {
    timeseries: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      showUnits: false,
      selected: this.fieldsToSelected(this.timeseries.fields),
      options: this.fieldsToOptions(this.timeseries.fields),
    };
  },
  methods: {
    csvLine(arr) {
      return arr.reduce((str, el, index) => {
        let bit = el;
        if  (index + 1 < arr.length) {
          bit += '\t';
          //bit = bit.padEnd(4, ' ');
        } else {
          bit += '\n';
        }
        return str + bit;
      }, '');
    },
    createCSV() {
      // labels
      const columns = ['index', 'timestamp'].concat(this.selected);
      let str = this.csvLine(columns);

      // units
      if (this.showUnits) {
        str += this.csvLine(['ordinal', 'ms'].concat(this.selected.map(key => {
          const field = this.timeseries.fields.find(field => field.key === key);
          return field.units;
        })));
      }

      this.timeseries.data.map((el, index) => {
        str += this.csvLine([index, this.timeseries.data[index].timestamp].concat(this.selected.map(key => el[key])));
      });

      // Save to local file

      const datasetMeta = JSON.parse(localStorage.getItem("source"));
      let filename = 'export.csv';
      console.log('-----', datasetMeta);
      if (datasetMeta && datasetMeta.filename) {
        filename = datasetMeta.filename.split('.')[0] + '-anaytics.csv';
      }
      const a = document.createElement('a');
      const blob = new Blob([str], {
        type: 'application/json',
      });

      a.href = URL.createObjectURL(blob);
      a.download = filename;
      a.style = 'display: none';
      a.click();
      URL.revokeObjectURL(a.href);

      //console.log('\n', str, '\n');
    },
    changed(keysOfEnabledAnalytics) {
      this.$emit("change", keysOfEnabledAnalytics);
    },
    fieldsToSelected(fields) {
      return fields.reduce((acc, field) => {
        if (field.enabled) {
          acc.push(field.key);
        }
        return acc;
      }, []);
    },
    fieldsToOptions(fields) {
      return fields.map((field) => {
        return {
          value: field.key,
          text: field.label,
        };
      });
    },
  },
};
</script>
