let keyPair = {};

export default {
  async generateKeyPair() {
    if (localStorage.getItem('privateKey')) {
      console.log(
        'private key already generated',
        // localStorage.getItem('privateKey')
      );
      return;
    }
    let uuid = self.crypto.randomUUID();
    localStorage.setItem('mobileAppId', uuid);

    // use browser crypto to generate rsa keypair and sign the dataset
    keyPair = await crypto.subtle.generateKey(
      {
        name: 'RSASSA-PKCS1-v1_5',
        modulusLength: 4096,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: 'SHA-256',
      },
      true,
      ['sign']
    );

    const privateKeyBuffer = await crypto.subtle.exportKey(
      'pkcs8',
      keyPair.privateKey
    );
    // const privateKeyPem = toPem(privateKeyBuffer, 'private');
    const privateKeyPem = arrayBufferToBase64(privateKeyBuffer);
    localStorage.setItem('privateKey', privateKeyPem);

    const exportedPublicKey = await crypto.subtle.exportKey(
      'spki',
      keyPair.publicKey
    );
    const publicKeyPem = toPem(exportedPublicKey, 'public');

    await this.$API.registerMobileApp(uuid, publicKeyPem);
  },
  async signDataset(dataset) {
    const privateKeyRaw = localStorage.getItem('privateKey');
    const binaryDer = pemToAB(privateKeyRaw);
    const privateKey = await crypto.subtle.importKey(
      'pkcs8',
      binaryDer,
      {
        name: 'RSASSA-PKCS1-v1_5',
        modulusLength: 4096,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: 'SHA-256',
      },
      true,
      ['sign']
    );

    const signature = await window.crypto.subtle.sign(
      {
        name: 'RSASSA-PKCS1-v1_5',
        hash: {
          name: 'SHA-256',
        },
      },
      // keyPair.privateKey,
      privateKey,
      new TextEncoder().encode(JSON.stringify(dataset))
    );
    // convert signature to hex string
    const hexSignature = Array.from(new Uint8Array(signature))
      .map((b) => b.toString(16).padStart(2, '0'))
      .join('');
    console.log('hexSignature', hexSignature);

    return hexSignature;
  },
};

function toPem(key, type) {
  const pemContents = breakPemIntoMultipleLines(arrayBufferToBase64(key));
  return `-----BEGIN ${type.toUpperCase()} KEY-----\n${pemContents}-----END ${type.toUpperCase()} KEY-----`;
}

function arrayBufferToBase64(arrayBuffer) {
  const byteArray = new Uint8Array(arrayBuffer);
  let byteString = '';
  byteArray.forEach((byte) => {
    byteString += String.fromCharCode(byte);
  });
  return window.btoa(byteString);
}

function breakPemIntoMultipleLines(pem) {
  const charsPerLine = 64;
  let pemContents = '';
  while (pem.length > 0) {
    pemContents += `${pem.substring(0, charsPerLine)}\n`;
    pem = pem.substring(64);
  }
  return pemContents;
}

function pemToAB(pem) {
  const str = window.atob(pem);
  const buf = new ArrayBuffer(str.length);
  const bufView = new Uint8Array(buf);
  for (let i = 0, strLen = str.length; i < strLen; i++) {
    bufView[i] = str.charCodeAt(i);
  }
  return buf;
}
