<template>
  <div id="app">
    <NavBar />
    <router-view />
  </div>
</template>

<script>
import NavBar from "./components/Navbar.vue";

export default {
  name: "app",
  components: {
    NavBar
  },
};
</script>

