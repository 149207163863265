<template>
  <input />
</template>

<script>
import {Auth} from '@aws-amplify/auth';
// https://codesandbox.io/s/tagify-tags-component-vue-example-forked-82too?file=/src/App.vue
import Tagify from "@yaireo/tagify";
import "@yaireo/tagify/dist/tagify.css";

export default {
  name: "TaggerWidget",
  props: {
    tagType: {
      type: String,
      default: null,
    },
    manage: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: 'Add a tag',
    },
  },
  data() {
    return {
      existingTags: [],
      datasetId: null,
      baseSettings: {
        pattern: /^.{1,90}$/,
        backspace: "edit",
        duplicates: false,
        dropdown: {
          mapValueTo: "value",
          enabled: 1,            // show suggestion after 1 typed character
          fuzzySearch: true,    // match only suggestions that starts with the typed characters
          position: 'text',      // position suggestions list next to typed text
          caseSensitive: false,   // allow adding duplicate items if their case is different
        },
        whitelist: [],
        tagTextProp: "value",
        callbacks: {
          add: (e) => {
            addTag(e.detail.data, this);
          },
          // remove(e) {
          //   console.log("tag removed ", e.detail.data.value);
          // },
        },
        hooks: {
          beforeRemoveTag: tags => {
            return new Promise((resolve, reject) => {
              const tag = tags[0];
              try {
                console.log('beforeRemoveTag:', tag.data);
                removeTag(tag.data, this);
              } catch (e) {
                alert('Error removing tag');
                reject();
              }
              resolve();
            })
          }
        },
      },
    };
  },
  beforeDestroy() {
    this.$root.$off("updateTags");
    this.$root.$off("updateWhitelist");
  },
  methods: {
  },
  async mounted() {
    this.tagify = new Tagify(this.$el, {
      placeholder: this.placeholder,
      ...this.baseSettings,
    });

    const data = await this.$API.getTags();
    const tags = data.map(e => ({...e, value: e.tag}));

    if (this.tagType === 'query') {
      this.tagify.settings.whitelist = tags;
      return;
    }

    this.tagify.settings.whitelist = tags.filter(e => e.type === this.tagType);
    console.log('WL', this.baseSettings.whitelist);
    if (this.manage) {
      this.existingTags = tags;
      this.tagify.settings.whitelist = [];
      this.tagify.addTags(tags.filter(e => e.type === this.tagType).map(e => ({readonly: true, ...e})));
    }

    this.$root.$on("updateTags", (detail) => {
      const tagValues = detail.tags.map(e => ({value: e.tag, ...e}));
      this.datasetId = detail.datasetId;
      this.existingTags = tagValues;
      this.tagify.removeAllTags();
      this.tagify.addTags(tagValues);
    });

    this.$root.$on("updateWhitelist", (tags) => {
      const tagValues = tags.map(e => ({value: e.tag, ...e}));
      this.tagify.whitelist(tagValues);
    });
  },
};

async function addTag(tag, self) {
  if (self.tagType === 'query' || self.existingTags.find(e => e.id === tag.id)) return;
  if (self.manage) {
    console.log('createTag result:', tag);
    const result = await this.$API.createTag({
      type: self.tagType,
      spaceId: null,
      tag: tag.value,
    });
    console.log('createTag result:', result);
    return;
  }

  const user = await Auth.currentAuthenticatedUser();
  let updateId = user.username;
  let addTagMethod = this.$API.addProfileTags;
  if (self.tagType === 'dataset') {
    addTagMethod = this.$API.addDatasetTags;
    updateId = self.datasetId;
  }
  await addTagMethod(updateId, [tag.id]);
}

async function removeTag(tag, self) {
  if (self.tagType === 'query') return;
  const user = await Auth.currentAuthenticatedUser();
  let updateId = user.username;
  let removeTagMethod = this.$API.removeProfileTag;
  if (self.tagType === 'dataset') {
    removeTagMethod = this.$API.removeDatasetTag;
    updateId = self.datasetId;
  }
  await removeTagMethod(updateId, tag.tagId);
}
</script>

<style>
.tagify {
  --tags-focus-border-color: transparent;
}

.tagify--outside {
  border: 0;
}

.tagify--outside .tagify__input {
  order: -1;
  flex: 100%;
  border: 1px solid var(--tags-border-color);
  margin-bottom: 1em;
  transition: 0.1s;
}

.tagify--outside .tagify__input:hover {
  border-color: var(--tags-hover-border-color);
}

.tagify--outside.tagify--focus .tagify__input {
  transition: 0s;
  border-color: var(--tags-focus-border-color);
}
</style>