<template>
  <b-modal size="lg" ref="marker-modal" hide-footer :title="title">
    <div class="container">
      <span v-if="!waiting">
        Press the number on the keyboard corresponding to your desired Mark type.
      </span>
      <b-spinner v-if="waiting" label="Spinning"></b-spinner>
      <div class="row">
        <div v-for="cnt in [0, 1]" class="col-sm" :key="cnt">
          <ul>
            <li v-for="key in markerKeys.slice(cnt === 0 ? cnt : markerCount / 2, cnt === 0 ? markerCount / 2 : markerCount)" :key="key">
              <b-button @click="handleKeypress({...baseMark, tag: MARKER_TYPES[key]})">
                {{ key }}: {{ MARKER_TYPES[key] }}
              </b-button>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>

export default {
  name: "ManageMotionMarks",
  props: {
    timeseries: {
      type: Object,
      required: true,
      default: () => ({
        data: [0],
      }),
    },
  },
  data() {
    return {
      waiting: false,
      currentOffset: 0,
      MARKER_TYPES: {
        a: 'left-heel-strike',
        b: 'right-heel-strike',
        c: 'left-toe-off',
        d: 'right-toe-off',
        e: 'squat-begin',
        f: 'squat-bottom',
        g: 'squat-finish',
        h: 'single-leg-squat-left-begin',
        i: 'single-leg-squat-left-bottom',
        j: 'single-leg-squat-left-finish',
        k: 'single-leg-squat-right-begin',
        l: 'single-leg-squat-right-bottom',
        n: 'single-leg-squat-right-finish',
        o: 'romanian-deadlift-left-begin',
        p: 'romanian-deadlift-left-bottom',
        q: 'romanian-deadlift-left-finish',
        r: 'romanian-deadlift-right-begin',
        s: 'romanian-deadlift-right-bottom',
        t: 'romanian-deadlift-right-finish',
        v: 'sit-to-stand-begin',
        w: 'sit-to-stand-complete',
        x: 'stand-to-sit-begin',
        y: 'stand-to-sit-complete',
        z: 'turning-left',
        3: 'turning-right',
        1: 'Thumbs Up',
        2: 'Thumbs Down',
      },
    };
  },
  computed: {
    markerCount() {
      return this.markerKeys.length;
    },
    markerKeys() {
      return Object.keys(this.MARKER_TYPES);
    },
    startTimestamp() {
      return this.timeseries.data[0].timestamp;
    },
    dataset() {
      return this.$store.getters.dataSetLoaded;
    },
    title() {
      return `Add Motion Mark at ${this.currentOffset}`;
    },
    baseMark() {
      return {
        type: 15,
        start: this.currentOffset,
        // note: "",
      };
    },
  },
  beforeDestroy() {
    console.log("ManageMotionMarks beforeDestroy");
    this.$root.$off("Mark");
    this.$root.$off("AnalyticsFrame");
  },
  mounted() {
    console.log("ManageMotionMarks mounted");

    this.$root.$on("Mark", async (evt) => {
      console.log("Mark event", this.currentOffset, this.dataset.id);
      // BEER: modularize
      switch (evt.key) {
        case "m":
          this.$refs["marker-modal"].show();
          break;
        default: {
          this.handleKeypress({...this.baseMark, tag: this.MARKER_TYPES[evt.key]});
          break;
        }
      }
    });

    this.$root.$on("AnalyticsFrame", (frame) => {
      // console.log("ManageMotionMarks AnalyticsFrame", frame);
      this.currentOffset = frame.timestamp;
    });
  },
  methods: {
    async handleKeypress(mark) {
      this.waiting = true;
      // console.log('handleKeypress', mark);
      const id = await this.$API.createMotionMark(this.dataset.id, mark);
      this.$refs["marker-modal"].hide();
      if (!id) {
        alert("failed to create motion mark");
        return;
      }
      this.$root.$emit("AddMarker", {
        ...mark,
      });
      this.waiting = false;
    },
  },
};
</script>
