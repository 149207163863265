<template>
  <div class="m-0 p-2 border border-secondary" id="canvascontainer" ref="canvascontainer">
          <canvas ref="canvas"></canvas>
      </div>
</template>

<script>
export default {
  components: {
  },
  name: 'Avatar-Canvas',
  async mounted() {
    //console.log("Avatar mounted", this.$refs);
    window.addEventListener('resize', this.onResize);
    this.$Scene.initContainer(this.$refs.canvascontainer, this.$refs.canvas);
  },
  beforeDestroy() {
    // Unregister the event listener before destroying this Vue instance
    window.removeEventListener('resize', this.onResize)
  },
  methods: {
    onResize() {
      this.$Scene.resize();
    }
  }
};
</script> 