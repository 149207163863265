import Vue from 'vue';
import Vuex from 'vuex'
import App from './App.vue';
import router from './router';
/*
import VueFusionCharts from 'vue-fusioncharts';
import FusionCharts from 'fusioncharts';
import Charts from 'fusioncharts/fusioncharts.charts'
*/

import * as AmplifyModules from 'aws-amplify';
import {Auth} from '@aws-amplify/auth'
import {API} from '@aws-amplify/api'

import {AmplifyPlugin} from 'aws-amplify-vue';

import {
  BForm,
  BFormFile,
  BFormGroup,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormSelect,
  BFormInput,
  BProgress,
  BProgressBar,
  BButton,
  BNavbar,
  BListGroup,
  BListGroupItem,
  BModal,
  ModalPlugin,
  NavbarPlugin,
  BRow,
  BCol,
  BTable,
  BSpinner,
} from 'bootstrap-vue';

// Can't figure out way to dynamically change the Amplify config after Vue as loaded.
//console.log('pENV', process.env);

import {
  devEnvironment,
  betaEnvironment,
  stageEnvironment,
  productionEnvironment,
  FrameBuilder,
  FrameCacheEvent,
  EventEmitter,
  apiUtil,
  getAnalyticsEngine,
  getDataSetsForProfile
} from '@motusi/sharedjs';

// Default to dev environment
let ENV = 'Dev';
let config = devEnvironment(Auth);

// Using VUE_APP_MOTUSI_ENV allows dev against specified environment without all the NodeJS production fancies
// to set the VUE_APP_MOTUSI_ENV var you must create a .env file in the root of the project
// with the contents: VUE_APP_MOTUSI_ENV=<environment> (e.g. VUE_APP_MOTUSI_ENV=stage)
if (process.env.VUE_APP_MOTUSI_ENV === 'beta') {
  ENV = 'Beta';
  config = betaEnvironment(Auth);
} else if (process.env.VUE_APP_MOTUSI_ENV === 'stage') {
  ENV = 'Stage';
  config = stageEnvironment(Auth);
} else if (process.env.VUE_APP_MOTUSI_ENV === 'production') {
  ENV = 'Production';
  config = productionEnvironment(Auth);
}

API.configure(config);
Auth.configure(config);

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import {Scene} from './util/scene.js';

const scene = new Scene();

Vue.prototype.$Scene = scene;
Vue.prototype.$AnalyticsEngine = getAnalyticsEngine();
Vue.prototype.$FrameBuilder = new FrameBuilder(
  (frame) => {
    Vue.prototype.$AnalyticsEngine.pushQuaternions(frame);
  },
  (e) => {console.log('FrameBuilder error handler:', e)},
  (s) => {console.log('FrameBuidler stat handler:', s)}
)
Vue.prototype.$AppData = {};
Vue.prototype.$Environment = ENV;
Vue.prototype.$API = {
  downloadDataset: getDataSetsForProfile(API).downloadDataset,
  ...apiUtil(API),
}

Vue.config.productionTip = false;
Vue.use(Vuex);
Vue.use(AmplifyPlugin, AmplifyModules);
Vue.use(NavbarPlugin);
//Vue.use(VueFusionCharts, FusionCharts, Charts);

Vue.component('b-progress-bar', BProgressBar);
Vue.component('b-progress', BProgress);
Vue.component('b-button', BButton);
Vue.component('b-form', BForm);
Vue.component('b-form-group', BFormGroup);
Vue.component('b-form-checkbox', BFormCheckbox);
Vue.component('b-form-checkbox-group', BFormCheckboxGroup);
Vue.component('b-form-select', BFormSelect);
Vue.component('b-navbar', BNavbar);
Vue.component('b-form-input', BFormInput);
Vue.component('b-form-file', BFormFile)
Vue.component('b-list-group', BListGroup);
Vue.component('b-list-group-item', BListGroupItem);
Vue.component('b-modal', BModal);
Vue.component('b-row', BRow);
Vue.component('b-col', BCol);
Vue.component('b-table', BTable)
Vue.component('b-spinner', BSpinner)
Vue.use(ModalPlugin);

const store = new Vuex.Store({
  state: {
    bleConnected: false,
    datasets: [],
    datasetSelected: 0,

    _kinetics: {
      fields: ['feature', 'data'],
      items: [
        {
          feature: "Ground Reaction",
          enabled: true,
          data: '',
          config: 'groundReactionForce',
        },
        {
          feature: 'Center of Mass',
          enabled: true,
          data: '',
          config: 'centerOfMass',
        },
      ]
    },
    _kinematics: {
      fields: ['feature', 'data'],
      items: [
        {
          feature: 'Knee',
          enabled: true,
          data: '',
          config: 'knee',
        },
        {
          feature: 'Hip',
          enabled: false,
          data: '',
          config: 'hip',
        },
        {
          feature: 'Sacral',
          enabled: false,
          data: '',
          config: 'sacral',
        },
      ],
    },
    dataSetLoaded: {
      label: 'none'
    }
  },
  mutations: {
    bleConnected(state, connected) {
      state.bleConnected = connected;
    },
    datasetlist(state, list) {
      state.datasets = list.map(e => e);
    },
    datasetSelected(state, index) {
      state.datasetSelected = index;
    },
    kinetics(state, list) {
      state._kinetics = list.map(e => e);
    },
    kinematics(state, list) {
      state._kinematics = list.map(e => e);
    },
    dataSetLoaded(state, obj) {
      state.dataSetLoaded = obj;
    }
  },
  getters: {
    bleConnected: state => {
      return state.bleConnected;
    },
    datasetlist: state => {
      return state.datasets;
    },
    datasetSelected: state => {
      return state.datasetSelected;
    },
    kinetics: state => {
      return state._kinetics
    },
    kinematics: state => {
      return state._kinematics;
    },
    dataSetLoaded: state => {
      return state.dataSetLoaded;
    }

  }
})

const vue = new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');

vue.$Scene.init(vue);

EventEmitter.on(
  FrameCacheEvent.State,
  (frame) => {vue.$root.$emit('MotionFrame', frame)}
);

EventEmitter.on(
  FrameCacheEvent.Analytics,
  (frame) => {vue.$root.$emit('AnalyticsFrame', frame)}
);

document.addEventListener('keydown', (e) => {
  if (e.key == 'ArrowLeft') {
    if (e.shiftKey) {
      vue.$root.$emit('Jump', 'Back', e);
    } else {
      vue.$root.$emit('Step', 'Back', e);
    }
  }
  if (e.key == 'ArrowRight') {
    if (e.shiftKey) {
      vue.$root.$emit('Jump', 'Forward', e);
    } else {
      vue.$root.$emit('Step', 'Forward', e);
    }
  }
  if ('abcdefghijklmnopqrstuvwxyz1234567890'.split('').includes(e.key)) {
    vue.$root.$emit('Mark', e);
  }
  if (e.code === 'Space') {
    vue.$root.$emit('PlayPause', e);
  }
});
