import { HarnessService } from './harness.js';

class BatteryService {
  uuid = null;
  level = {};

  constructor() {
    this.uuid = 0x180f;
    this.level = {
      uuid: 0x2a19,
      value: '',
    };
  }

  async connect(server) {
    this._service = await server.getPrimaryService(this.uuid);
    this.level._char = await this._service.getCharacteristic(this.level.uuid);
  }

  disconnect() {
    delete this._service;
    delete this.level._char;
  }

  async readLevel() {
    const buf = await this.level._char.readValue();
    return new DataView(buf.buffer).getInt8(0);
  }

  async writeLevel(level) {
    const buffer = new ArrayBuffer(1);
    const view = new DataView(buffer);
    view.setUint8(0, level);
    await this.level._char.writeValue(view);
  }
}

class DeviceInfoService {
  uuid = 0x180a;
  firmwareVersion = {
    uuid: 0x2a26,
    value: '',
  };

  constructor() {}

  async connect(server) {
    console.log(server);
    this._service = await server.getPrimaryService(this.uuid);
    this.firmwareVersion._char = await this._service.getCharacteristic(
      this.firmwareVersion.uuid
    );
  }

  disconnect() {
    delete this._service;
    delete this.firmwareVersion._char;
  }

  async readFirmwareVersion() {
    const valueBuffer = await this.firmwareVersion._char.readValue();
    const enc = new TextDecoder('utf-8');
    return enc.decode(valueBuffer);
  }
}

function getLogMessageHandler() {
  return (event) => {
    var enc = new TextDecoder('utf-8');
    let msg = enc.decode(event.target.value.buffer);
    console.log(`${msg}\n`);
  };
}

export default class BLE {
  constructor() {
    this.connected = false;
    this.disconnectHandler = this.onDisconnect();
    this.harnessService = new HarnessService();
    this.batteryService = new BatteryService();
    this.deviceInfoService = new DeviceInfoService();
  }

  onDisconnect() {
    return (event) => {
      console.log(`bluetooth disconnect event`, event);
      this.connected = false;
    };
  }

  async startLogging() {
    console.log('logging from device...');
    this.harnessService.logging._char.addEventListener(
      'characteristicvaluechanged',
      getLogMessageHandler()
    );
    await this.harnessService.logging._char.startNotifications();
  }

  async connect(deviceNotificationHandler) {
    try {
      this.device = await navigator.bluetooth.requestDevice({
        filters: [{ namePrefix: 'Motusi' }],
        optionalServices: [
          this.harnessService.uuid,
          this.batteryService.uuid,
          this.deviceInfoService.uuid,
        ],
      });
      this.deviceNotificationHandler = deviceNotificationHandler;
    } catch (e) {
      console.warn('connect failed', e);
      return;
    }

    console.log('connect done getting server');

    this.server = await this.device.gatt.connect();
    this.primaryService = await this.server.getPrimaryService(this.harnessService.uuid);

    console.log('connect add disconnect handler');
    this.device.removeEventListener('gattserverdisconnected', this.disconnectHandler);
    this.disconnectHandler = this.onDisconnect();
    this.device.addEventListener('gattserverdisconnected', this.disconnectHandler);

    await this.batteryService.connect(this.server);
    await this.deviceInfoService.connect(this.server);
    await this.harnessService.connect(this.server);

    await this.startLogging();
    await this.checkTime();

    await this.enableSensorFrameNotifications();

    this.connected = true;
  }

  disconnect() {
    console.log('bluetooth disconnect', this);
    if (this.device.gatt.connected) {
      this.device.gatt.disconnect();
    }
    this.batteryService.disconnect();
    this.deviceInfoService.disconnect();
    this.harnessService.disconnect();

    this.connected = false;
  }

  async enableSensorFrameNotifications() {
    this.harnessService.enableSensorFrameNotifications(
      this.deviceNotificationHandler
    );
  }

  async readBatteryLevel() {
    return await this.batteryService.readLevel();
  }

  async writeBatteryLevel(batteryLevel) {
    console.log('---------> write battery level', batteryLevel);
    await this.batteryService.writeLevel(batteryLevel);
  }

  async readFirmwareVersion() {
    return await this.deviceInfoService.readFirmwareVersion();
  }

  setDevicePlugState(harnessPlugged) {
    this.harnessPlugged = harnessPlugged;
  }
  
  getDevicePlugState() {
    return this.harnessPlugged;
  }

  async resetDevice() {
    await this.harnessService.resetDevice();
  }
  
  async checkTime() {
    const val = await this.harnessService.time._char.readValue();
    const obcTime = Number(val.getBigUint64(0, true));
    const localTime = Date.now();
    console.log(`Checking OBC time ${obcTime} ${localTime}`);
    if (Math.abs(obcTime - localTime) > 100) {
      console.log('Updating OBC time');
      const buffer = new ArrayBuffer(8);
      const view = new DataView(buffer);
      view.setBigInt64(0, BigInt(localTime), true);
      await this.harnessService.time._char.writeValue(view);
      console.log('Updated Time');
    }
  }
}
