import {Quaternion, toRadians} from "@motusi/sharedjs";

// Rotation between SharedJS and ThreeJS coordinate system as viewed from ThreeJS
const toThreeJsTransform = Quaternion.fromEuler(
  toRadians(-90), 
  toRadians(0), 
  toRadians(-90)
);

// Convert a 3D point from SharedJS to ThreeJS
export function pointToThreeJs(point) {
  return [point[1], point[2], point[0]];
}

// Convert a quaternion from SharedJS to ThreeJS
export function quaternionToThreeJs(quaternion) {
  const rotated = new Quaternion(quaternion).transform(toThreeJsTransform);
  return rotated.toScalarLastVector();
}
