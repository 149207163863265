import {Auth} from '@aws-amplify/auth';
import TaggerWidget from "../common/Tagger.vue";

async function logout(event) {
  console.log(event);
  await Auth.signOut();
  console.log("signout complete");
  this.$router.push({name: "login"});
}

export default {
  name: "User",
  components: {
    TaggerWidget,
  },
  data() {
    return {
      userInfo: [],
      tagType: 'profile',
    };
  },
  async created() {
    const user = await Auth.currentAuthenticatedUser();
    this.userInfo.push({key: "Cognito User Id", value: user.username});
    this.userInfo.push({key: "email", value: user.attributes.email});
    this.userInfo.push({
      key: "Cognito User Pool",
      value: user.pool.userPoolId,
    });
    this.userInfo.push({key: "Environment", value: this.$Environment});
    const tags = await this.$API.getProfileTags(user.username);
    this.$root.$emit("updateTags", {
      profileId: user.username,
      tags: tags,
    });
  },
  methods: {
    logout,
  },
};
