import Vue from "vue";
import Router from "vue-router";
import Viz from "./../components/visualization/visualization.vue";
import UserInfo from "./../components/user-info/user-info.vue";
import DataSets from "./../components/datasets/index.vue";
import LoginPage from "./../components/Login.vue";
import Admin from "./../components/Admin.vue";
import Video from "./../components/video/index.vue";


import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import {Auth} from "@aws-amplify/auth";

AmplifyEventBus.$on("authState", (info) => {
  console.log(
    `Here is the auth event that was just emitted by an Amplify component: ${info}`
  );
  if (info == "signedIn") {
    window.location.href = "/";
  }
});

function requireAuth(to, from, next) {
  return Auth.currentAuthenticatedUser()
    .then(() => {
      next();
    })
    .catch(() => {
      //console.log('Auth.currentAuthenticatedUser err', err);
      next({
        path: "/login",
        query: {redirect: to.fullPath},
      });
    });
}

async function requireGroup(...args) {
  const user = await Auth.currentAuthenticatedUser();
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"] || [];
  if (!groups.includes("MOTUSI_ADMIN")) {
    return false;
  }
  return requireAuth(...args);
}

Vue.use(Router);

export default new Router({
  routes: [
    {
      path: "/login",
      name: "login",
      component: LoginPage
    },
    {
      path: "/vis",
      name: "visualization",
      component: Viz,
      beforeEnter: requireAuth,
    },
    {
      path: "/datasets",
      name: "datasets",
      component: DataSets,
      beforeEnter: requireAuth,
    },
    {
      path: "/user-info",
      name: "user-info",
      component: UserInfo,
      beforeEnter: requireAuth,
    },
    {
      path: "/admin",
      name: "admin",
      component: Admin,
      beforeEnter: requireGroup,
    },
    {
      path: "/video",
      name: "video",
      component: Video,
      beforeEnter: requireAuth,
    },
    // {
    //   path: "/ai-research",
    //   name: "ai-research",
    //   component: AIResearch,
    //   beforeEnter: requireAuth,
    // },
    {
      path: "/*",
      redirect: '/datasets'
    }
  ],
});
