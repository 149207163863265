class Metric {
  constructor(report, reportInterval) {
    this.counter = 0;
    this.report = report;
    this.reportInterval = reportInterval;
  }

  increment = (val = 1) => {
    this.counter += val;
  };

  start = () => {
    this.timer = setInterval(this.report, this.reportInterval * 1000, this);
  };

  stop = () => {};
}

export class ProfilingTimer {
  metric = {};

  addMetric = (obj) => {
    if (obj.name === undefined) {
      console.log(
        "ERROR: failed to addMetric to profiling timer. no name specified"
      );
      return;
    }

    // reportInterval is the number of seconds between reports beeing shown.
    if (obj.reportInterval == undefined) {
      console.log(
        "ERROR: failed to addMetric to profiling timer. no report interval specified"
      );
      return;
    }

    // report is a callback used to show reports.
    if (obj.report == undefined) {
      console.log(
        "ERROR: failed to addMetric to profiling timer. no report interval specified"
      );
      return;
    }

    this.metric[obj.name] = new Metric(obj.report, obj.reportInterval);
  };
}
