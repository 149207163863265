<template>
  <div class="panel border border-secondary">
    <div v-b-toggle.collapse-sensors class="header">
      Sensors
    </div>
    <b-collapse notvisible id="collapse-sensors">
      <div class="m-1">
        <b-form inline>
          <label for="sensor-display-select">Display Format</label>
          <b-form-select
            v-model="displayFormat"
            :options="options"
          ></b-form-select>
        </b-form>
      </div>
      <div class="row row-cols-1 row-cols-md-2 g-0">
        <!-- Add a column for each harness -->
        <div class="col" 
          v-for="garment in garments" 
          :key="garment.name" >
          <div class="card m-2">
            <div class="card-body">
              <div class="card-title">{{garment.name}}</div>
                <div class="panel panel-info border">
                  <SensorViz
                    v-for="sensor in garment.sensors"
                    v-bind:key="sensor.index"
                    v-bind:index="sensor.index"
                    v-bind:displayFormat="displayFormat"
                  ></SensorViz>
                </div>
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>


<script>
import SensorViz from './sensor.vue'
import { SensorMap, Garment } from "@motusi/sharedjs";

export default {
  name: "SensorsTab",
  components: {
    SensorViz
  },
  data() {
    return {
      garments: [
        {
          name: Garment.SHIRT,
          sensors: [
            { index: SensorMap['torso'] },
            { index: SensorMap['right-upper-arm'] },
            { index: SensorMap['right-lower-arm'] },
            { index: SensorMap['left-upper-arm'] },
            { index: SensorMap['left-lower-arm'] }
          ]
        },
        {
          name: Garment.PANT,
          sensors: [
            { index: SensorMap['hip'] },
            { index: SensorMap['right-upper-leg'] },
            { index: SensorMap['right-lower-leg'] },
            { index: SensorMap['left-upper-leg'] },
            { index: SensorMap['left-lower-leg'] }
          ]
        }
      ],
      options: [
        { value: 'sensor', text: 'sensor' },
        { value: 'model', text: 'model' },
      ],
      displayFormat: 'sensor',
    }    
  },
}
</script>
