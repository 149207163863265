import * as THREE from 'three'
import {pointToThreeJs} from "./coordinate-systems";
import OrbitControls from 'three-orbitcontrols'
import Rig from "./rig.js";

const SCALE = 100;

const CAM_X = -100;
const CAM_Y = 100;
const CAM_Z = -280;

const CAM_LOOK_X = 0;
const CAM_LOOK_Y = 60;
const CAM_LOOK_Z = 0;

const sep = 0; // visual separation

const materials = [
  new THREE.MeshLambertMaterial({color: 0xd5da71}), // left  - yellow
  new THREE.MeshLambertMaterial({color: 0xfc9003}), // right - orange
  new THREE.MeshLambertMaterial({color: 0x7303fc}), // top - purple
  new THREE.MeshLambertMaterial({color: 0x0339fc}), // bottom - blue
  new THREE.MeshLambertMaterial({color: 0xfc1303}), // front - red
  new THREE.MeshLambertMaterial({color: 0x036b74}), // back - green
];

const YELLOW = 0xffff00;

const ASPECT = 16/9;

export function Scene() {

  this.rig = new Rig();

  this.resize = () => {
    const width = this.container.clientWidth - 16;
    const height = width * ASPECT;
    this.renderer.setSize(width, height);
  }

  this.init = (vue) => {
    //console.log('scene.init', vue);
    this.rig.init(vue);
  }

  this.initContainer = (container, canvas) => {
    //console.log('scene.initContainer', container, canvas);
    //console.log('scene', container.offsetWidth, container.offsetHeight);
    this.container = container;

    this.meshes =  {};
    this.scene = new THREE.Scene();
    this.camera = new THREE.PerspectiveCamera(30, 1, 0.1, 1000 );
    this.renderer =  new THREE.WebGLRenderer({canvas});
    this.canvas = this.renderer.domElement;

    this.container.appendChild(this.renderer.domElement);
    this.resize();
    
    this.controls = new OrbitControls (this.camera, this.renderer.domElement);
    this.controls.enableZoom = false;
    this.controls.update();

    this.camera.position.set(CAM_X, CAM_Y, CAM_Z);
    this.camera.lookAt(0,6,0);
    this.controls.target.set(CAM_LOOK_X, CAM_LOOK_Y, CAM_LOOK_Z);

    this.gridXZ = new THREE.GridHelper(100, 10);
    this.scene.add(this.gridXZ);

    const ambientLight = new THREE.AmbientLight(0xffffff, 0.5);
    this.scene.add(ambientLight);

    const ptLight = new THREE.DirectionalLight(0xffffff, 0.5);
    this.scene.add(ptLight);

    this.animate();
    
    const grfGeometry = new THREE.ConeGeometry( 5, 20, 32 );
    const grfMaterial = new THREE.MeshBasicMaterial( {color: YELLOW} );
    this.rightGrf = new THREE.Mesh(grfGeometry, grfMaterial);
    this.rightGrf.position.set(-40, -10, 0);
    this.leftGrf = new THREE.Mesh(grfGeometry, grfMaterial);
    this.leftGrf.position.set(40, -10, 0);
    this.scene.add(this.rightGrf);
    this.scene.add(this.leftGrf);

    this.leftGrfAccel = new THREE.ArrowHelper(new THREE.Vector3(0, 1, 0), new THREE.Vector3(25, 0, 0), 1, YELLOW);
    this.rightGrfAccel = new THREE.ArrowHelper(new THREE.Vector3(0, 1, 0), new THREE.Vector3(-25, 0, 0), 1, YELLOW);
    this.scene.add(this.rightGrfAccel);
    this.scene.add(this.leftGrfAccel);

    const geometry = new THREE.SphereGeometry(1);
    const material = new THREE.MeshBasicMaterial({ color: YELLOW });
    this.sphere = new THREE.Mesh(geometry, material);
    this.scene.add(this.sphere);

    this.rig.initScene(this);

    this.recording = false;

    this.leftFoot = new THREE.Vector3();
    this.rightFoot = new THREE.Vector3();
  }

  this.animate = () => {
    requestAnimationFrame(this.animate);

    this.controls.update();
    this.renderer.render(this.scene, this.camera);
  }

  this.meshUpdate = (name, center, rotation) => {
    const mesh = this.meshes[name];

    if (mesh) {
      mesh.position.set(
        center.x * SCALE,
        center.y * SCALE,
        center.z * SCALE
      );

      mesh.quaternion.set(
        rotation.x,
        rotation.y,
        rotation.z,
        rotation.w
      );
      //console.log('meshUpdate', name, center, rotation, mesh.position, mesh.quaternion);
      //console.log('meshUpdate', name, mesh.position, mesh.quaternion);
    }
  }

  this.storeFeetPosition = (leftFoot, rightFoot) => {
    // Convert coordinates to three JS
    this.leftFoot = new THREE.Vector3()
      .fromArray(pointToThreeJs(Object.values(leftFoot)))
      .multiplyScalar(SCALE);
    this.rightFoot = new THREE.Vector3()
      .fromArray(pointToThreeJs(Object.values(rightFoot)))
      .multiplyScalar(SCALE);
  }

  this.addCoM = (pos, height) => {
    this.sphere.position.set(pos[0] * SCALE * height , pos[1] * SCALE * height, pos[2] * SCALE * height);
  }
  
  this.addGroundReactionForces = (forceLeft, forceRight) => {
    const left = new THREE.Vector3(forceLeft[0], forceLeft[1], forceLeft[2]);
    const right = new THREE.Vector3(forceRight[0], forceRight[1], forceRight[2]);
    this.rightGrf.scale.set(1, right.length(), 1);
    this.rightGrf.rotateOnAxis(right, 0);
    this.leftGrf.scale.set(1, left.length(), 1);
    this.leftGrf.rotateOnAxis(left, 0);
  }

  this.addGroundReactionForcesAccel = (forceLeft, forceRight) => {
    const left = new THREE.Vector3().fromArray(pointToThreeJs(forceLeft));
    const right = new THREE.Vector3().fromArray(pointToThreeJs(forceRight));

    // Don't display if the magnitude is zero
    this.rightGrfAccel.visible = right.length() > 0;
    this.leftGrfAccel.visible = left.length() > 0;

    if (this.leftGrfAccel.visible) {
      const origin = this.leftFoot.sub(left);
      this.leftGrfAccel.position.set(origin.x, origin.y, origin.z);
      this.leftGrfAccel.setLength(left.length());
      this.leftGrfAccel.setDirection(left.normalize());
    }

    if (this.rightGrfAccel.visible) {
      const origin = this.rightFoot.sub(right);
      this.rightGrfAccel.position.set(origin.x, origin.y, origin.z);
      this.rightGrfAccel.setLength(right.length());
      this.rightGrfAccel.setDirection(right.normalize());
    }
  }

  this.add = (obj) => {

    // select dims based on shared js version. this line can go after we upgrade
    // to 1.10 of shared js

    const dims = obj.part ? obj.part.dims : obj.params.dims;

    let geometry = new THREE.BoxGeometry(
      SCALE * (dims[0] - sep),
      SCALE * (dims[1] - sep),
      SCALE * (dims[2])
    );
   
    const mesh = new THREE.Mesh(geometry, materials);
    mesh.name = obj.name;
    this.meshes[obj.name] = mesh;
    this.scene.add(mesh);
  }
}
