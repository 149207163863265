<template>

  <div class="panel border border-secondary">
    <div v-b-toggle.blecol class="header">
      Bluetooth
    </div>
    <b-collapse notvisible id="blecol">
    <div class="row row-cols-1 row-cols-md-2 g-0">
      <div class="col">
        <div class="card m-2">
          <div class="card-body">
            <div class="card-title">Device 1</div>
            <p class="card-text">
              <BLEDevice 
                ref="device0"
                :deviceId="0"
                @GarmentTypeChangeEvent="onGarmentTypeChanged"
                @BleConnectedEvent="onBleConnected"
                @BleDataEvent="onBleData"
               class="col-med" 
              />
            </p>
          </div>
        </div>
      </div>
      <div class="col">
        <div class="card m-2">
          <div class="card-body">
            <div class="card-title">Device 2</div>
            <p class="card-text">
              <BLEDevice 
                ref="device1"
                :deviceId="1" 
                @GarmentTypeChangeEvent="onGarmentTypeChanged"
                @BleConnectedEvent="onBleConnected"
                @BleDataEvent="onBleData"
                class="col-med" 
              />
            </p>
          </div>
        </div>
      </div>
    </div>
    <pre style="font-size: 11px">{{frameBuilderStats}}</pre>
    </b-collapse>
 
  </div>
</template>

<script>
import { Garment, ObcFrame } from "@motusi/sharedjs";
import BLEDevice from "../ble-device/template.vue";
// import {socketSender} from "../../util/socket";

export default {
  components: {
    BLEDevice,
  },
  name: "BLE",
  data() {
    return {
      live: false,
      frameBuilderStats: '',
      resetQInitials: false
    }
  },
  computed: {
    bleConnected() {
      return this.$store.getters.bleConnected;
    },
  },
  async mounted() {
    console.log('BLE mounted');
  },
  async created() {
    console.log("BLE created", this.$refs);
    this.$FrameBuilder.statHandler = (stats) => {
      this.frameBuilderStats = stats;
    };
  },
  methods: {
    onGarmentTypeChanged(deviceId, type) {
      console.log('onGarmentTypeChanged', deviceId, type);
      const thisDevice = this.$refs[`device${deviceId}`];
      const otherDevice = this.$refs[`device${deviceId == 0 ? 1 : 0}`];
      thisDevice.garmentType = type;
      if (otherDevice.garmentType != Garment.None) {
        otherDevice.garmentType = type ==  Garment.SHIRT ? Garment.PANT : Garment.SHIRT;
      }
      this.resetQInitials = true;
    },
    onBleConnected(deviceId) {
      console.log('onBleConnected', deviceId);
      const thisDevice = this.$refs[`device${deviceId}`];
      const otherDevice = this.$refs[`device${deviceId == 0 ? 1 : 0}`];

      if (otherDevice.garmentType == Garment.SHIRT) {
        thisDevice.garmentType = Garment.PANT;
      } else {
        thisDevice.garmentType = Garment.SHIRT;
      }

      if (this.live == false) {
        this.$root.$emit('LiveMode');
        this.$store.commit('bleConnected', true);
        this.live = true;
      }
      
      this.resetQInitials = true;
    },
    onBleData(deviceId, obcFrame) {
      const thisDevice = this.$refs[`device${deviceId}`];
      const otherDevice = this.$refs[`device${deviceId == 0 ? 1 : 0}`];

      // socketSender.send({
      //   garmentType: thisDevice.garmentType,
      //   ...obcFrame,
      // });
      this.$FrameBuilder.push(thisDevice.garmentType, obcFrame);

      if (otherDevice.garmentType == Garment.None) {
        // So we can work with a single device
        const otherFrame = new ObcFrame();
        otherFrame.timestamp = obcFrame.timestamp; // align timestamps
        const otherType = thisDevice.garmentType == Garment.SHIRT ? Garment.PANT : Garment.SHIRT;
        this.$FrameBuilder.push(otherType, otherFrame);
      }

      if (this.resetQInitials) {
        this.$Scene.rig.setQInitials();
        this.resetQInitials = false;
      }
    }
  },
};
</script>
