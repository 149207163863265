<template>
  <div class="card">
    <div class="card-header">Environment is {{ $Environment }}</div>
    <div class="card-body">
      <custom-sign-in v-if="displayMap.showSignIn" />
      <amplify-sign-up v-if="displayMap.showSignUp" v-bind:signUpConfig="signUpConfig" v-bind:usernameAttributes="usernameAttributes"/>
      <amplify-confirm-sign-up v-if="displayMap.showConfirmSignUp" v-bind:confirmSignUpConfig="confirmSignUpConfig" v-bind:usernameAttributes="usernameAttributes"/>
      <amplify-confirm-sign-in v-if="displayMap.showConfirmSignIn" v-bind:confirmSignInConfig="confirmSignInConfig"/>
      <amplify-forgot-password v-if="displayMap.showForgotPassword" v-bind:forgotPasswordConfig="forgotPasswordConfig" v-bind:usernameAttributes="usernameAttributes"/>
      <amplify-require-new-password v-if="displayMap.requireNewPassword" v-bind:requireNewPasswordConfig="requireNewPasswordConfig"/>
      <amplify-set-mfa v-if="displayMap.showMfa" v-bind:mfaConfig="mfaConfig"/>
    </div>
  </div>
</template>

<script>

import {Auth} from '@aws-amplify/auth';
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus"
import ConfirmSignIn from "aws-amplify-vue/src/components/authenticator/ConfirmSignIn.vue";
import ConfirmSignUp from "aws-amplify-vue/src/components/authenticator/ConfirmSignUp.vue";
import ForgotPassword from "aws-amplify-vue/src/components/authenticator/ForgotPassword.vue";
import RequireNewPassword from "aws-amplify-vue/src/components/authenticator/RequireNewPassword.vue";
import SetMFA from "aws-amplify-vue/src/components/authenticator/SetMFA.vue";
import SignUp from "aws-amplify-vue/src/components/authenticator/SignUp.vue";
import SignInForm from "./SignInForm.vue";


async function getUser() {
  return await Auth.currentAuthenticatedUser()
    .then(user => {
      if (!user) {
        return null;
      }
      return user;
    })
    .catch(e => new Error(e));
}

export default {
  name: 'LoginPage',
  components: {
    'custom-sign-in': SignInForm,
    'amplify-sign-up': SignUp,
    'amplify-confirm-sign-up': ConfirmSignUp,
    'amplify-confirm-sign-in': ConfirmSignIn,
    'amplify-forgot-password': ForgotPassword,
    'amplify-require-new-password': RequireNewPassword,
    'amplify-set-mfa': SetMFA,
  },
  data() {
    return {
      user: {
        username: null
      },
      displayMap: {},
      usernameAttributes: 'email',
      signUpConfig: {
        header: 'Sign up for an account',
        hiddenDefaults: ['phone_number', 'username'],
        signUpFields: [
          {
            label: 'Email',
            key: 'email',
            type: 'string',
            required: true,
            displayOrder: 0
          },
          {
            label: 'Password',
            key: 'password',
            type: 'password',
            required: true,
            displayOrder: 1
          }
        ]
      },
      signInConfig: {},
      confirmSignUpConfig: {},
      confirmSignInConfig: {},
      forgotPasswordConfig: {},
      mfaConfig: {},
      requireNewPasswordConfig: {},
    }
  },
  async mounted() {
    AmplifyEventBus.$on('localUser', user => {
      this.user = user;
      this.signInConfig.username = this.user.username;
      this.confirmSignInConfig.user = this.user;
      this.confirmSignUpConfig.username = this.user.username;
      this.requireNewPasswordConfig.user = this.user;
    });
    AmplifyEventBus.$on('authState', data => {
      this.displayMap = this.updateDisplayMap(data)
    });
    getUser().then((val) => {
      if (val instanceof Error) {
        this.displayMap = this.updateDisplayMap('signedOut')
        return AmplifyEventBus.$emit('authState', 'signedOut')
      }
      this.user = val;
      this.displayMap = this.updateDisplayMap('signedIn');
      return AmplifyEventBus.$emit('authState', 'signedIn')
    })
    .catch(e => console.error(e))
  },
  methods: {
    updateDisplayMap: state => {
      return {
        showSignIn: state === 'signedOut' || state === 'signIn',
        showSignUp: state === 'signUp',
        showConfirmSignUp: state === 'confirmSignUp',
        showConfirmSignIn: state === 'confirmSignIn',
        showForgotPassword: state === 'forgotPassword',
        showSignOut: state === 'signedIn',
        showMfa: state === 'setMfa',
        requireNewPassword: state === 'requireNewPassword'
      }
    },
  }
}
</script>

<style src='@aws-amplify/ui/dist/style.css'></style>
