<template>
  <div>
    <DataSets @dataLoaded="dataLoaded" />
  </div>
</template>

<script>
import DataSets from "../common/DataSets.vue";

export default {
  name: 'DataSetContainer',
  components: {
    DataSets,
  },
  data() {
    return {};
  },
  methods: {
    dataLoaded(context) {
      document.location = `#/vis?datasetid=${context.id}`;
    },
  },
};
</script>