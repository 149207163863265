<template>
  <div class="panel border border-secondary">
    <div v-b-toggle.plotspan class="header">Plots</div>

    <b-collapse visible id="collapse-plotspan">
      <div class="card m-1">
        <LineChartGenerator ref="plot" :chart-options="chartOptions" :chart-data="chartData" :chart-id="chartId"
          :dataset-id-key="datasetIdKey" :plugins="plugins" :css-classes="cssClasses" :styles="styles" :width="width"
          :height="height" />
      </div>
      <manage-motion-marks :timeseries="timeseries" />
    </b-collapse>
  </div>
</template>

<script>
import {Line as LineChartGenerator} from "vue-chartjs/legacy";
import ManageMotionMarks from "./visualization/ManageMotionMarks.vue";
import moment from "moment";

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
} from "chart.js";
import annotationPlugin from "chartjs-plugin-annotation";

import {CrosshairPlugin} from "chartjs-plugin-crosshair";

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  CategoryScale,
  PointElement,
  CrosshairPlugin,
  annotationPlugin
);

const crosshair = {
  line: {
    color: "rgb(99, 99, 99)",
  },
  zoom: {
    enabled: false,
  },
};

const annotation = {
  annotations: {
    timeCursor: {
      type: "line",
      xMax: 0,
      xMin: 0,
      borderColor: "rgb(255, 99, 132)",
      borderWidth: 2,
      drawTime: "afterDraw",
    },
  },
};

export default {
  name: "PlotsPanel",
  components: {
    LineChartGenerator,
    ManageMotionMarks,
  },
  props: {
    timeseries: {
      type: Object,
      required: true,
      default: () => ({
        data: [],
      }),
    },
    timelineTags: {
      type: Array,
      required: false,
      default: () => [],
    },
    chartId: {
      type: String,
      default: "line-chart",
    },
    datasetIdKey: {
      type: String,
      default: "label",
    },
    width: {
      type: Number,
      default: 400,
    },
    height: {
      type: Number,
      default: 400,
    },
    cssClasses: {
      default: "",
      type: String,
    },
    styles: {
      type: Object,
      default: () => { },
    },
    plugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      markIndexes: [],
      currentIndex: 0,
      minY: 0,
      maxY: 0,
      startTime: 0,
      chartData: {
        labels: this.timeseries.data.map((el, idx) => idx),
        datasets: [...this.timeseriesToDatasets()],
      },
      chartOptions: {
        animation: false,
        onClick: this.onClick,
        scales: {
          y: {
            //afterDataLimits: this.afterDataLimits
          }
        },
        responsive: true,
        maintainAspectRatio: true,
        plugins: {
          autocolors: false,
          crosshair,
          annotation,
          tooltip: {
            callbacks: {
              label: this.toolTipLabel,
              title: this.toolTipTitle,
            },
          },
        },
      },
    };
  },

  beforeDestroy() {
    console.log("PlotsPanel beforeDestory");
    this.$root.$off("AnalyticsFrame");
    this.$root.$off("AddMarker");
    this.$root.$off("Jump");
    for (const key in this.chartOptions.plugins.annotation.annotations) {
      if (key === "timeCursor") {
        continue;
      }
      this.chartOptions.plugins.annotation.annotations[key] = null;
    }
    this.markIndexes = [];
  },

  mounted() {
    console.log("PlotsPanel mounted", this.chartData);
    this.$root.$on('AnalyticsFrame', frame => {
      this.currentIndex = frame.index;
      if (this.currentIndex % 2 === 0) {
        // the update is throttled to so the movement appears smoother
        const chart = this.$refs.plot.getCurrentChart();
        if (chart) {
          chart.options.plugins.annotation.annotations.timeCursor.xMax = this.currentIndex;
          chart.options.plugins.annotation.annotations.timeCursor.xMin = this.currentIndex;
          chart.update();
        }
      }
    });
    this.timelineTags.forEach(this.addTimelineTag);
    this.$root.$on("AddMarker", this.addTimelineTag);

    this.$root.$on("Jump", (direction, e) => {
      e.preventDefault();
      const jumpsUp = this.markIndexes.filter(e => e >= 0).sort();
      const jumpsDown = this.markIndexes.filter(e => e >= 0).sort().reverse();
      const forwardIndex = jumpsUp.find(e => e >= this.currentIndex);
      const backIndex = jumpsDown.find(e => e <= this.currentIndex);
      console.log(
        'Jump index:',
        this.currentIndex,
        forwardIndex,
        backIndex,
      );
      if (direction === "Forward") {
        console.log('FOR');
        this.$AnalyticsEngine.getPlayback().stepForward();
        this.$AnalyticsEngine.getPlayback().scrubToIndex(forwardIndex);
      } else {
        console.log('BACK');
        this.$AnalyticsEngine.getPlayback().scrubToIndex(backIndex);
        this.$AnalyticsEngine.getPlayback().stepBack();
      }
      console.log('after', this.currentIndex, jumpsUp);
    });
  },
  methods: {
    onClick(e) {
      let points = this.$refs.plot
        .getCurrentChart()
        .getElementsAtEventForMode(e, "nearest", {intersect: true}, true);

      if (points.length) {
        // The click occured on or near a point
        this.currentIndex = points[0].index;
        const dataset = this.chartData.datasets[points[0].datasetIndex];
        const key = dataset.key;
        const timestamp = this.timeseries.data[this.currentIndex].timestamp;
        const value = this.timeseries.data[this.currentIndex][key];

        console.log(
          `      click near data ${key} point this.currentIndex ${this.currentIndex} timestamp ${timestamp} ${value}`
        );
      } else {
        // Click not near a point, but on the chart, find the nearest timestamp.
        points = this.$refs.plot
          .getCurrentChart()
          .getElementsAtEventForMode(e, "index", {intersect: false}, false);
        this.currentIndex = points[0].index;
        const timestamp = this.timeseries.data[this.currentIndex].timestamp;

        console.log(
          `      click NOT near data point this.currentIndex ${this.currentIndex} timestamp ${timestamp}`
        );
      }

      this.$root.$emit("Step", "Back", {preventDefault() { } });
      this.$AnalyticsEngine.getPlayback().scrubToIndex(this.currentIndex);
    },
    timeseriesToDatasets() {
      const enabled = this.timeseries.fields.reduce((acc, el) => {
        if (el.enabled) {
          acc.push(el);
        }
        return acc;
      }, []);

      return enabled.map((field) => {
        return {
          data: this.timeseries.data.map((el) => el[field.key]),
          label: field.label,
          key: field.key,
          backgroundColor: field.color,
        };
      });
    },
    addTimelineTag(tag) {
      if (!this.timeseries.data || !this.timeseries.data.length) return;

      const index = this.timeseries.data.findIndex(
        (el) => el.timestamp == tag.start
      );
      const annotationRef = this.chartOptions.plugins.annotation.annotations;
      this.markIndexes.push(index);
      this.chartOptions.plugins.annotation.annotations[index] = {
        type: "line",
        xMax: index,
        xMin: index,
        borderColor: tag.type === 15 ? 'FireBrick' : 'CadetBlue',
        borderWidth: 8,
        drawTime: "afterDraw",
        label: {
          backgroundColor: "lightBlue",
          content: tag.tag || tag.note,
          display: (ctx) => ctx.hovered,
          id: tag.id,
        },
        enter(ctx) {
          ctx.hovered = true;
          ctx.chart.update();
        },
        leave(ctx) {
          ctx.hovered = false;
          ctx.chart.update();
        },
        click: function({element}) {
          console.log("Marker clicked", element);
          const ref = element.elements[0].options;
          if (confirm(`Delete label ${ref.content} — ${ref.id}`)) {
            annotationRef[element.$context.id] = null;
            this.$API.deleteMotionMark(ref.id);
          }
        },
      };
    },
    toolTipTitle(context) {
      if (context.length) {
        const dataIndex = parseInt(context[0].label);
        const timestamp = this.timeseries.data[dataIndex].timestamp;
        return new Date(timestamp).toLocaleString();
      }
    },
    toolTipLabel(context) {
      //console.log("=====>", context);
      if (context.dataset.label !== "Motion Marks") {
        return context.dataset.label + ": " + context.formattedValue;
      }
      const startTime = this.timeseries.data[0].timestamp;
      const label = this.timelineTags.find((e) => {
        return moment(
          this.timeseries.data[context.dataIndex].timestamp
        ).isBetween(
          moment(e.start + startTime).subtract(5, "ms"),
          moment(e.start + startTime).add(5, "ms")
        );
      }) || {note: "Motion Marker"};
      return label?.note || label?.type;
    },
    update() { },
  },
};
</script>
