import { ObcFrame } from "@motusi/sharedjs";

export class HarnessService {
  eventListener = null;

  uuid = '01016435-986c-e492-874c-b34f4fc27400';

  time = {
    name: 'time',
    uuid: '01016435-986c-e492-874c-b34f4fc27450',
  };

  logging = {
    name: 'logging',
    uuid: '01016435-986c-e492-874c-b34f4fc27451',
  };

  plugged = {
    name: 'plugged',
    uuid: '01016435-986c-e492-874c-b34f4fc27452',
  };

  reset = {
    name: 'reset',
    uuid: '01016435-986c-e492-874c-b34f4fc27454',
  };

  sensorData = {
    name: 'sensorData',
    uuid: '01016435-986c-e492-874c-b34f4fc27457',
  };

  async connect(server) {
    const _this = this;

    _this._service = await server.getPrimaryService(_this.uuid);

    _this.time._char = await this._service.getCharacteristic(_this.time.uuid);
    _this.logging._char = await this._service.getCharacteristic(
      _this.logging.uuid
    );
    _this.sensorData._char = await this._service.getCharacteristic(
      _this.sensorData.uuid
    );

    try {
      _this.plugged._char = await this._service.getCharacteristic(
        _this.plugged.uuid
      );
    } catch (e) {
      console.log('failed to connect to the plugged characteristic', e);
    }
  }

  disconnect() {
    delete this._service;
    delete this.sensorData._char;
    delete this.time._char;
    delete this.logging._char;
    delete this.plugged._char;
  }

  async resetDevice() {
    console.log('resetting device');
    if (!this.reset._char) {
      this.reset._char = await this._service.getCharacteristic(this.reset.uuid);
    }
    if (this.reset._char) {
      const buffer = new ArrayBuffer(1);
      const view = new DataView(buffer);
      view.setUint8(0, 1);
      await this.reset._char.writeValue(view);
    }
  }

  async enableSensorFrameNotifications(deviceHandler) {
    console.log('add listener sensor data characteristicvaluechanged');
    this.eventListener = this.getFrameNotificationHandler(deviceHandler);
    this.sensorData._char.addEventListener('characteristicvaluechanged', this.eventListener);
    await this.sensorData._char.startNotifications();
    console.log('Sensor data notifications begin');
  }

  getFrameNotificationHandler(deviceHandler) {
    return (event) => {
      const obcFrame = new ObcFrame();
      obcFrame.parse(event.target.value.buffer);
      deviceHandler(obcFrame);
    };
  }
}
