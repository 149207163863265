<template>
  <div class="m-0 p-2 border border-secondary">
    <div class="card m-1">
      <div v-b-toggle.collapse-alignment class="card-header">
        <small>Alignment</small>
      </div>
      <b-collapse id="collapse-alignment">
        <div class="card-body">
          <b-button 
            style="font-size: smaller" 
            class="m-1" 
            v-on:click="clearQInitials"
            >Clear QInitials
          </b-button>
          <b-button 
            style="font-size: smaller" 
            class="m-1" 
            v-on:click="setQInitials"
            >Set QInitials
          </b-button>
          <b-button 
            style="font-size: smaller" 
            class="m-1" 
            v-on:click="beginAlignment"
            >Calibrate Alignment
          </b-button>
          <br />
          Alignment State: {{ alignmentState }}
          <br />
        </div>
      </b-collapse>
    </div>
  </div>
</template>
  
<script>
import {
  AlignmentType,
  AlignmentEvent,
  AlignmentState,
  EventEmitter,
} from "@motusi/sharedjs";

export default {
  name: "AlignmentControls",
  data() {
    return {
      alignmentState: "Not Started",
    };
  },
  methods: {
    setQInitials() {
      this.$Scene.rig.setQInitials();
    },
    clearQInitials() {
      this.$Scene.rig.clearQInitials();
    },
    beginAlignment() {
      this.$AnalyticsEngine.initialize(); // resets alignment
      const alignment = this.$AnalyticsEngine.getAlignment();
      const handler = (msg) => {
        this.alignmentState = AlignmentState[msg.state] + ` ${msg.info}`;
        if (msg.state === AlignmentState.Sensor_Reset) {
          this.$root.$emit("resetObc");
          alignment.beginSensorReset();
        }
      };
      EventEmitter.on(AlignmentEvent.Update, handler);
      alignment.begin(AlignmentType.Version_1);
    },
  },
};
</script>
