<template>
  <div v-bind:class="amplifyUI.formSection">
    <div v-bind:class="amplifyUI.sectionHeader">Sign in to your account</div>
    <div v-bind:class="amplifyUI.sectionBody">
      <div v-bind:class="amplifyUI.formField">
        <div v-bind:class="amplifyUI.inputLabel">Email</div>
        <input v-bind:class="amplifyUI.input" v-model="username" type="email"
          :placeholder="$Amplify.I18n.get('Enter your Email')" v-on:keyup.enter="signIn" />
      </div>
      <div v-bind:class="amplifyUI.formField">
        <div v-bind:class="amplifyUI.inputLabel">Password</div>
        <input v-bind:class="amplifyUI.input" v-model="password" type="password"
          :placeholder="$Amplify.I18n.get('Enter your password')" v-on:keyup.enter="signIn" />
        <div v-bind:class="amplifyUI.hint">
          Forgot your password?
          <a v-bind:class="amplifyUI.a" v-on:click="forgot">Reset password</a>
        </div>
      </div>
    </div>
    <div v-bind:class="amplifyUI.sectionFooter">
      <span v-bind:class="amplifyUI.sectionFooterPrimaryContent">
        <button v-bind:class="amplifyUI.button" v-on:click="signIn">Sign In</button>
      </span>
      <span v-bind:class="amplifyUI.sectionFooterSecondaryContent">
        {{ $Amplify.I18n.get('No account? ') }}
        <a v-bind:class="amplifyUI.a" v-on:click="signUp">{{ $Amplify.I18n.get('Create account') }}</a>
      </span>
    </div>
    <div class="error" v-if="error">
      {{ error }}
    </div>
  </div>
</template>


<script>
import {Auth} from '@aws-amplify/auth';
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import * as AmplifyUI from '@aws-amplify/ui';

export default {
  name: "SignInForm",
  data() {
    return {
      username: '',
      password: '',
      error: '',
      amplifyUI: AmplifyUI,
    }
  },
  methods: {
    signIn: function (event) {
      void event;
      Auth.signIn(this.username.toLowerCase(), this.password)
        .then(data => {
          console.log('sign in success');
          if (data.challengeName === 'SMS_MFA' || data.challengeName === 'SOFTWARE_TOKEN_MFA') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'confirmSignIn')
          } else if (data.challengeName === 'NEW_PASSWORD_REQUIRED') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'requireNewPassword');
          } else if (data.challengeName === 'MFA_SETUP') {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'setMfa');
          } else if (data.challengeName === 'CUSTOM_CHALLENGE' &&
            data.challengeParam &&
            data.challengeParam.trigger === 'true'
          ) {
            AmplifyEventBus.$emit('localUser', data);
            return AmplifyEventBus.$emit('authState', 'customConfirmSignIn')
          } else {
            return AmplifyEventBus.$emit('authState', 'signedIn')
          }
        })
        .catch((e) => {
          if (e.code && e.code === 'UserNotConfirmedException') {
            AmplifyEventBus.$emit('localUser', { username: this.username })
            AmplifyEventBus.$emit('authState', 'confirmSignUp')
          } else {
            this.error = e.message || e;
            console.error(this.error)
          }
        });
    },
    forgot: function () {
      AmplifyEventBus.$emit('authState', 'forgotPassword')
    },
    signUp: function () {
      AmplifyEventBus.$emit('authState', 'signUp')
    },
  }
}

</script>

<style src='@aws-amplify/ui/dist/style.css'></style>
