import {Garment} from '@motusi/sharedjs';
import BLE from '../../util/bluetooth';
// import {socketSender} from '../../util/socket';

export default {
  components: {
  },
  name: 'BLEDevice',
  props: {
    deviceId: Number,
  },
  data() {
    return {
      battery: 0,
      firmware: null,
      topBottomOptions: [
        {value: Garment.SHIRT, text: Garment.SHIRT},
        {value: Garment.PANT, text: Garment.PANT},
        {value: Garment.None, text: Garment.None},
      ],
      BLE: null,
      rate: 0,
      rateCounter: 0,
      interval: 0,
      latency: 0,
      frameBuilderStats: '',
      garmentType: Garment.None
    };
  },
  watch: {
    'BLE.connected': function (value) {
      if (value == false) {
        this.battery = 0;
        this.firmware = null;
      }
      console.log(`BLE ${this.deviceId} ${value ? "connected" : "disconnected"}`)
    }
  },
  async mounted() {
    this.interval = setInterval(() => {
      this.rate = this.rateCounter;
      this.rateCounter = 0;
    }, 1000);
    this.interval2 = setInterval(async () => {
      if (this.BLE.connected) {
        this.battery = await this.BLE.readBatteryLevel();
      }
    }, 2000);
    this.$root.$on("resetObc", () => {
      this.resetObc();
    });
  },
  async created() {
    this.garmentType = Garment.None;
    this.BLE = new BLE();
    this.BLE.setDevicePlugState(this.garmentType);
  },
  beforeDestroy() {
    console.log('bleDevice destroy');
  },
  methods: {
    async onConnectButton() {
      if (this.BLE.connected) {
        console.log('disconnect');
        this.BLE.disconnect();
        // socketSender.close();
      } else {
        console.log('connect');
        // socketSender.init();
        await this.BLE.connect(this.deviceEventHandler);
        this.$emit('BleConnectedEvent', this.deviceId);
        this.battery = await this.BLE.readBatteryLevel();
        this.firmware = await this.BLE.readFirmwareVersion();
      }
    },
    deviceEventHandler(e) {
      this.$emit('BleDataEvent', this.deviceId, e);
      this.rateCounter++;
      this.latency = Date.now() - e.timestamp;
    },
    garmentTypeChange(garmentType) {
      this.$emit('GarmentTypeChangeEvent', this.deviceId, garmentType)
    },
    resetObc() {
      console.log('resetting OBC');
      this.BLE.resetDevice();
    },
  },
};
