<template>
  <div>
    <link
      href="https://unpkg.com/video.js@7/dist/video-js.min.css"
      rel="stylesheet"
    />
    <div>
      <b-button v-on:click="record"> Record </b-button>
      <b-button v-on:click="stop"> Stop </b-button>
      <a id="download-video" download="test.webm">Download Video</a>
      <video id="video" width="320" height="240" autoplay></video>
    </div>
    <ul>
      <li v-for="vid in available" :key="vid">
        <b-button @click="playSelected(vid)">
          {{ vid }}
        </b-button>
      </li>
    </ul>
    <div>
      <div id="timer"></div>
      <video ref="videoPlayer" class="video-js"></video>
    </div>
  </div>
</template>

<script>
import { Storage } from "aws-amplify";
import videojs from "video.js";

export default {
  components: {},
  name:"VideoComponent",
  data() {
    return {
      video: null,
      mediaRecorder: null,
      blobs: [],
      downloadLink: null,
      player: null,
      options: {
        autoplay: true,
        controls: true,
        sources: [],
      },
      available: [],
    };
  },
  methods: {
    list() {
      Storage.list("").then(
        (list) => (this.available = list.map((item) => item.key))
      );
    },
    async record() {
      console.log("record!");
      this.video.srcObject = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: false,
      });

      this.mediaRecorder = new MediaRecorder(this.video.srcObject, {
        mimeType: "video/webm",
      });

      // event : new recorded video blob available
      this.mediaRecorder.addEventListener("dataavailable", (e) => {
        this.blobs.push(e.data);
      });

      // event : recording stopped & all blobs sent
      this.mediaRecorder.addEventListener("stop", async () => {
        // create local object URL from the recorded video blobs
        const fileBlob = new Blob(this.blobs, { type: "video/webm" });
        let video_local = URL.createObjectURL(fileBlob);
        this.downloadLink.href = video_local;
        try {
          await Storage.put(`${Date.now()}.webm`, fileBlob, {
            progressCallback(progress) {
              console.log(`Uploaded: ${progress.loaded}/${progress.total}`);
            },
            // contentType: "video/webm",
          });
          this.list();
        } catch (err) {
          console.log("error uploading", err);
        }
        console.log("finished?");
      });

      // start recording with each recorded blob having 1 second video
      this.mediaRecorder.start(1000);
    },
    stop() {
      this.mediaRecorder.stop();
      this.video.srcObject.getTracks().forEach(function (track) {
        track.stop();
      });
    },
    playSelected(vid) {
      console.log("PLAY!", vid);
      this.player.src(
        `https://mobile-app-stage-user-storage121943-stage.s3.us-west-1.amazonaws.com/public/${vid}`
      );
    },
  },
  mounted() {
    this.video = document.querySelector("#video");
    this.downloadLink = document.querySelector("#download-video");
    this.player = videojs(this.$refs.videoPlayer, this.options, () => {
      this.player.log("onPlayerReady", this);
    });
    this.$refs.videoPlayer.addEventListener("timeupdate", function () {
      const matches = this.currentSrc.match(/(\d+)\.webm/);
      if (!Array.isArray(matches) || isNaN(matches[1])) return;
      document.getElementById("timer").innerHTML =
        Math.floor(this.currentTime * 1000 + +matches[1]);
    });
    this.list();
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>