
<template>
  <div class="panel border border-secondary">
    <div v-b-toggle.collapse-data class="header">
      Data
    </div>

    <b-collapse notvisible id="collapse-data">
      <div class="card m-1">
        <b-table 
          table-class="table-xsmall" 
          small 
          class="smtext" 
          ref="table" 
          selectable 
          responsive 
          sticky-header="400px" 
          :current-page="currentPage" 
          :per-page="perPage" 
          :filter="filter" 
          primary-key="timestamp"
          :fields="fields" 
          :items="itemProvider" 
          @refreshed="refreshed" 
          @row-clicked="rowClicked">
        </b-table>
      </div>
      <div class="card m-1">
        <b-table 
          table-class="table-xsmall" 
          small 
          class="smtext" 
          ref="stats" 
          responsive 
          sticky-header="200px" 
          :fields="statFields" 
          :items="statItems" 
          ></b-table>
      </div>
    </b-collapse>
  </div>
</template>


<style scoped>
.smtext {
  font-size: 10px;
}
</style>

<script>

export default {
  name: 'DataTablePanel',
  props: {
    timeseries: {
      type: Object,
      required: true
    }
  },
  data: () => {
    return {
      fields: [],
      filter: null,
      perPage: 10,
      currentPage: 1,
      statFields: [
        { key: 'feature'},
        { key: 'value'}, 
        { key: 'units'},
        { key: 'occurs_at'}
      ],
      statItems: []
    }
  },

  beforeDestroy() {
    //console.log('DataTablePanel beforeDestory');
    this.$root.$off('AnalyticsFrame');
  },

  mounted() {
    //console.log('DataTablePanel mounted');
    this.$root.$on('AnalyticsFrame', e => {
      const page = Math.floor(e.index / this.perPage);
      this.currentPage = page + 1;
      this.selectIndex = e.index % this.perPage;
      this.$refs.table.clearSelected();
      this.$refs.table.selectRow(this.selectIndex);
    })
    this.update();
  },
  methods: {
    update() {
      if (!this.timeseries.fields.length) {
        return
      }
      //console.log('DataTablePanel update');
 
      this.fields = this.toBootstrapTableFields(this.timeseries.fields);
      this.statItems = this.createStatItems();
      this.currentPage = 1;
      this.selectIndex = 1;
    },
    createStatItems() {
      const tsFields = this.timeseries.fields;
      const parent = this;

 //     console.log('xjjxxx', JSON.stringify(tsFields), this.timeseries.fields);
     
      function itemFromElement(el) {
        const field = tsFields.find(e => e.key == el);

        //console.log('xxxx', parent.$AnalyticsEngine.stats, field)

        const stat = parent.$AnalyticsEngine.stats(field.key, 'max');
        return {
          feature: 'Max ' + field.label,
          units: field.units,
          value: stat.value,
          occurs_at: stat.timestamp
        }
      }

      const enabled = this.timeseries.fields.reduce((acc,el) => {
        if (el.enabled) {
          acc.push(el.key);
        }
        return acc;
      }, []);

      //console.log('createStatItems enabled', enabled)
      return enabled.map(itemFromElement);
    },
    rowClicked(ctx) {
      // oddly the ctx.index is the frame index.
      this.$AnalyticsEngine.getPlayback().scrubToIndex(ctx.index);
      this.$root.$emit('Step', 'Back', { preventDefault() { } });
    },
    refreshed() {
      //console.log('refreshed index:', this.selectIndex);
      this.$refs.table.selectRow(this.selectIndex);
    },
    itemProvider(ctx) {
      const index = (ctx.currentPage - 1) * ctx.perPage;
      const ret = this.timeseries.data.slice(index, index + ctx.perPage);
      this.$refs.table.selectRow(this.selectIndex);
      return ret;
    },
    toBootstrapTableFields(fields) {
      // takes fields from analytics engine and converts to bootstrap table fields.
      return fields.reduce((acc, el) => {
        if (el.enabled) {

        acc.push({
          key: el.key,
          label: el.units ? el.label + ' (' + el.units + ')' : el.label,
        });
        }
        return acc;
      }, [{ key: 'timestamp', label: 'Timestamp' }]);
    },
  },
}
</script>