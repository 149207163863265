<template>
      <div class="col-12">
        <div class="card no-gutters">
          <div v-b-toggle.recinfo class="card-header">
            dataSet id: {{ dataSetLoaded ? dataSetLoaded.id: 'no dataset loaded' }}
          </div>
          <b-collapse id="recinfo">
            <div class="card-body">
              <pre>{{ JSON.stringify(dataSetLoaded, null, 2) }}</pre>
            </div>
          </b-collapse>
        </div>
      </div>
</template>

<script>
export default {
	name: 'LoadedRecording',
	computed: {
		dataSetLoaded() {
			return this.$store.getters.dataSetLoaded;
		}
  }
}
</script>