<template>
  <div class="m-2 border">    
    <small>
      <pre>{{label}}<br>{{info}}</pre>
    </small>
    <div id="sensorcanvas" ref="sensorcanvas">
      <canvas ref="canvassensor"></canvas>
    </div>
  </div>
</template>

<script>
import * as THREE from 'three';
import OrbitControls from 'three-orbitcontrols';
import {
  format,
  SensorMap,
  FrameType,
  getPartModelData,
  ModelStateElement
} from '@motusi/sharedjs';

// using const for size of 3js renderer because if the tab
// is collapsed on render then the size is 0x0;
const WIDTH = 200;
const HEIGHT = 100;

const RED = 0xeb4034;
const BLUE = 0x294ef2;
const GREEN = 0x42f548;
const CYAN = 0x36e7ff;
const YELLOW = 0xf7e13b;
const MAGENTA = 0xe62af7;

function toThreeJs(q) {
  // converts a sharedjs quaternion to a threejs quaternion
  if (q.x) {
    return new THREE.Quaternion(q.x, q.y, q.z, q.w);
  } else {
    return new THREE.Quaternion(q[1], q[2], q[3], q[0]);
  }
}

function createCube() {
  const CUBE_FACE = .5;
  const piece = new THREE.BoxGeometry(CUBE_FACE, CUBE_FACE, CUBE_FACE).toNonIndexed();
  const material = new THREE.MeshBasicMaterial({ vertexColors: true });

  // Use custom colors for each side of cube
  const colorMap = [	
    RED,
    MAGENTA,
    GREEN,    
    YELLOW,    
    BLUE,
    CYAN
  ];

  const positionAttribute = piece.getAttribute('position');
  const colors = [];
  const color = new THREE.Color();

  for (let i = 0; i < positionAttribute.count; i += 6) {
    color.setHex(colorMap[i/6]);
    for(let j = 0; j < 6; j++) {
      colors.push(color.r, color.g, color.b);
    }
  } 

  piece.setAttribute('color', new THREE.Float32BufferAttribute(colors, 3));

  return new THREE.Mesh(piece, material);
}

export default {
  name: 'SensorViz',
  props: {
    index: { required: true },
    displayFormat: { required: true }
  },
  data() {
    return {
      label: `${SensorMap[this.index]}: index ${this.index}`,
      info: '',
    }
  },
  methods: {    
    updateDisplay(q, accel, angVel) {
      this.cube.quaternion.copy(toThreeJs(q));
      this.info = `${this.displayFormat}: ${format(q, 2)}`;
      this.info += `\naccel: ${format(accel, 1)}`;
      this.info += `\nang vel: ${format(angVel, 1)}`;
    },
    resetCamera() {
      const CAM_DISTANCE = 1.5;
      this.camera.position.set(CAM_DISTANCE, CAM_DISTANCE, CAM_DISTANCE);
      this.camera.lookAt(0, 0, 0);
    },
    handleFrame(frame) {
      if (!frame) {
        return;
      } 

      if (frame.type === FrameType.ModelStateFrame) {
        const format = this.displayFormat === 'model' ? ModelStateElement.QMODEL : ModelStateElement.QSENSOR;
        const q = getPartModelData(frame, this.index, format);
        const accel = getPartModelData(frame, this.index, ModelStateElement.ACCEL);
        const angVel = getPartModelData(frame, this.index, ModelStateElement.ANG_VEL);
        this.updateDisplay(q, accel, angVel);
      } 
    },
    initScene() {
      const container = this.$refs.sensorcanvas;
      const canvas = this.$refs.canvassensor;

      //console.log('sensor initScene x', canvas, container.offsetHeight)

      this.scene = new THREE.Scene();
      this.scene.add(new THREE.AmbientLight(0xffffff, 0.5));
      this.scene.add(new THREE.DirectionalLight(0xffffff, 0.5));

      this.camera = new THREE.PerspectiveCamera(30, WIDTH/HEIGHT, 0.1, 1000);
      this.resetCamera();
      
      this.renderer =  new THREE.WebGLRenderer({canvas});
      this.renderer.setSize(WIDTH, HEIGHT);
      this.renderer.domElement.ondblclick=this.resetCamera;

      this.controls = new OrbitControls (this.camera, this.renderer.domElement);
      this.controls.enablePan = false;

      container.appendChild(this.renderer.domElement);

      this.cube = createCube();
      this.scene.add(this.cube);

      const axesHelper = new THREE.AxesHelper();
      this.scene.add(axesHelper);

      const animate = () => {
        requestAnimationFrame(animate);
        this.controls.update();
        this.renderer.render(this.scene, this.camera);
      };

      animate();
    }
  },
  mounted() {
    this.$root.$on("MotionFrame", (frame) => {
      this.handleFrame(frame)
    });

    this.initScene();
  }
}
</script>


<style scoped>
#sensorcanvas {
    width: 200px;
    height: 100px;
}
</style>
