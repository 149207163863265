<template>
  <div class="m-0 p-2 border border-secondary">
    <b-progress
      ref="progress"
      class="m-1 progress"
      :max="frameCount"
      @click.native="progressclicked"
    >
      <b-progress-bar
        class="bg-secondary"
        style="transition: none"
        :value="currentFrame"
        :label="`${currentFrame} of ${frameCount} frames`"
      ></b-progress-bar>
    </b-progress>
    <b-button
      style="font-size: smaller"
      class="m-1"
      v-on:click="record"
      :disabled="false"
      ref="record"
      >Record</b-button
    >
    <b-button
      style="font-size: smaller"
      class="m-1"
      v-on:click="play"
      :disabled="!(loaded() === true)"
      ref="play"
      >Play
    </b-button>
    <b-button
      style="font-size: smaller"
      class="m-1"
      v-on:click="faster"
      :disabled="!(playing() === true)"
      >Faster
    </b-button>
    <b-button
      style="font-size: smaller"
      class="m-1"
      v-on:click="slower"
      :disabled="!(playing() === true)"
      >Slower
    </b-button>
    <br/>
    playback speed: {{ speed }}
    <br/>
    press 'm' to add a Motion Marker
    <br />
    <div class="card m-1">
      <div v-b-toggle.collapse-trimrec class="card-header">
        <small>Trim Recording</small>
      </div>
      <b-collapse id="collapse-trimrec">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="m0 col-4"><small>First Frame</small></div>
              <div class="m0 p0 col-3">
                <b-form-input
                  size="sm"
                  type="number"
                  v-model="firstFrame"
                ></b-form-input>
              </div>
              <div class="m0 col-5">
                <b-button
                  style="font-size: smaller"
                  size="sm"
                  class="m-1"
                  v-on:click="setFirstFrame"
                  >Set</b-button
                >
                <b-button
                  style="font-size: smaller"
                  size="sm"
                  class="m-1"
                  v-on:click="clearFirstFrame"
                  >Clear</b-button
                >
              </div>
            </div>
            <div class="row">
              <div class="m0 col-4"><small>Last Frame</small></div>
              <div class="m0 col-3">
                <b-form-input
                  size="sm"
                  type="number"
                  v-model="lastFrame"
                ></b-form-input>
              </div>
              <div class="m0 col-5">
                <b-button
                  style="font-size: smaller"
                  size="sm"
                  class="m-1"
                  v-on:click="setLastFrame"
                  >Set</b-button
                >
                <b-button
                  style="font-size: smaller"
                  size="sm"
                  class="m-1"
                  v-on:click="clearLastFrame"
                  >Clear</b-button
                >
              </div>
                <b-button
                  style="font-size: smaller"
                  size="sm"
                  class="m-1"
                  v-on:click="saveTrimmed"
                  >Save</b-button
                >
            </div>
          </div>
        </div>
      </b-collapse>
    </div>
  </div>
</template>

<script>
import LZString from "lz-string";
import { toDataSet, AnalyticsEngine } from "@motusi/sharedjs";
import cryptoUtils from '../../util/crypto';

const STATE = {
  INIT: 0,
  PLAYING: 1,
  PAUSED: 2,
  CONNECTED: 3,
  RECORDING: 4,
};

export default {
  name: "PlaybackControls",
  data() {
    return {
      state: STATE.INIT,
      recording: false,
      frameCount: 0,
      currentFrame: 0,
      playOnLoad: true,
      speed: 1,
      datasetMeta: {},
      firstFrameIsSet: false,
      firstFrame: 0,
      lastFrameIsSet: false,
      lastFrame: 0,

      alignmentState: "unknown",
    };
  },
  computed: {
    bleConnected() {
      return this.$store.getters.bleConnected;
    },
  },
  beforeDestroy() {
    console.log("playback beforeDestroy");
    this.$AnalyticsEngine.getPlayback().pause();
    this.$root.$off("LiveMode");
    this.$root.$off("MotionFrame");
    this.$root.$off("PlayPause");
    this.$root.$off("Step");
  },
  mounted() {
    this.datasetMeta = JSON.parse(localStorage.getItem("source"));
    console.log("mounted datasetMeta", this.datasetMeta);

    let i = 1;
    if (!i) {
      return;
    }

    this.$root.$on("LiveMode", () => {
      console.log("Playback live mode");
      this.$AnalyticsEngine.initialize();
      this.$AnalyticsEngine.setLiveMode();
      localStorage.removeItem("source");
      localStorage.removeItem("recording");
      this.frameCount = 0;
    });

    this.$root.$on("MotionFrame", (frame) => {
      if (this.firstFrameIsSet === true) {
        if (frame.index < this.firstFrame) {
          this.$AnalyticsEngine.getPlayback().scrubToIndex(this.firstFrame);
        }
      } else {
        this.firstFrame = frame.index;
      }

      if (this.lastFrameIsSet === true) {
        if (frame.index > this.lastFrame) {
          if (this.firstFrameIsSet === true) {
            this.$AnalyticsEngine.getPlayback().scrubToIndex(this.firstFrame);
          } else {
            this.$AnalyticsEngine.getPlayback().scrubToIndex(0);
          }
        }
      } else {
        this.lastFrame = frame.index;
      }
      this.currentFrame = frame.index;

      if (this.currentFrame >= this.frameCount - 3 && this.recording) {
        this.saveMovie();
      }
    });

    this.$root.$on("PlayPause", (e) => {
      e.preventDefault();
      this.play(); // bit of a misnomer - this toggles play/pause
    });

    this.$root.$on("Step", (direction, e) => {
      e.preventDefault();
      if (this.state == STATE.PLAYING) {
        this.play(); // bit of a misnomer - this toggles play/pause
      }
      if (direction === "Forward") {
        this.$AnalyticsEngine.getPlayback().stepForward();
      } else {
        this.$AnalyticsEngine.getPlayback().stepBack();
      }
    });

    if (this.bleConnected) {
      console.log(`Live ModloadedRecordinge bleConnected ${this.bleConnected}`);
      return;
    }

    if (this.$AnalyticsEngine.frameCache.frameCount == 0) {
      //console.log('------ FrameCache is empty loading from localstorage');
      try {
        this.$store.commit('dataSetLoaded', this.datasetMeta);
        const str = LZString.decompress(localStorage.getItem("recordingLZ"));
        const obj = JSON.parse(str);
        this.$AnalyticsEngine.loadDataSet(obj);

        if (this.playOnLoad) {
          this.play();
        }
      } catch (e) {
        console.log("did not load dataset from local storage");
      }
    } else {
      console.log("Playback Resuming play of previously loaded dataset", this.$AnalyticsEngine.body.getQInitials());
      if (this.playOnLoad) {
        this.play();
      }
    }
    this.frameCount = this.$AnalyticsEngine.frameCache.frameCount;
  },
  methods: {
    loaded() {
      return this.state == STATE.PLAYING || this.state == STATE.PAUSED;
    },
    record() {
      console.log("Playback.record");
      if (this.state == STATE.RECORDING) {
        this.$AnalyticsEngine.captureComplete();
        this.state = STATE.INIT;
        this.$refs.record.innerText = "Record";
        toDataSet(this.$AnalyticsEngine.frameCache).then((obj) => {
          const dataSet = JSON.parse(obj);

          // Save to local file
          const a = document.createElement("a");
          const blob = new Blob([JSON.stringify(dataSet, null, 2)], {
            type: "application/json",
          });

          a.href = URL.createObjectURL(blob);
          a.download = `${dataSet.movementName}-${dataSet.startTime}`;
          a.style = "display: none";
          a.click();
          URL.revokeObjectURL(a.href);
        });
      } else {
        this.$AnalyticsEngine.captureBegin();
        this.state = STATE.RECORDING;
        this.$refs.record.innerText = "Stop Rec.";
      }
    },
    pause() {
      if (this.state == STATE.PLAYING) {
        this.state = STATE.PAUSED;
        this.$AnalyticsEngine.getPlayback().pause();
        this.$refs.play.innerText = "Play";
        return;
      }
    },
    play() {
      if (this.state == STATE.PLAYING) {
        this.pause();
        return;
      }
      this.state = STATE.PLAYING;
      this.$AnalyticsEngine.getPlayback().play();
      this.$refs.play.innerText = "Pause";
    },
    playing() {
      return this.state == STATE.PLAYING;
    },
    faster() {
      if (this.speed < 3) {
        this.speed += 0.25;
      }
      this.$AnalyticsEngine.getPlayback().speed = this.speed;
    },
    slower() {
      if (this.speed > 0.25) {
        this.speed -= 0.25;
      }
      this.$AnalyticsEngine.getPlayback().speed = this.speed;
    },
    saveMovie() {
      console.log("saveMovie");
      this.pause();
      this.$Scene.rig.saveMovie();
      this.recording = false;
    },
    progressclicked(event) {
      this.pause();
      const percent = event.offsetX / this.$refs.progress.$el.clientWidth;
      const frame = (
        this.$AnalyticsEngine.frameCache.frameCount * percent
      ).toFixed();
      this.$AnalyticsEngine.getPlayback().scrubToIndex(frame);
      this.$AnalyticsEngine.getPlayback().stepForward();
    },
    setFirstFrame() {
      this.firstFrameIsSet = true;
      this.firstFrame = this.currentFrame;
    },
    setLastFrame() {
      this.lastFrameIsSet = true;
      this.lastFrame = this.currentFrame;
    },
    clearFirstFrame() {
      this.firstFrameIsSet = false;
      this.firstFrame = 0;
    },
    clearLastFrame() {
      this.lastFrameIsSet = false;
    },
    async saveTrimmed() {
      console.log('save trimmed');
      let window = {
        firstFrame: 0
      };

      if (this.firstFrameIsSet === true) {
        window.firstFrame = this.firstFrame;
      } 
      
      if (this.lastFrameIsSet === true) {
        window.lastFrame = this.lastFrame;
      } 

      // get an unsigned version of the currenly loaded dataset.
      //

      const str = await toDataSet(this.$AnalyticsEngine.frameCache);
      const dataset = JSON.parse(str);

      // reload that dataset with the window applied.
      dataset.reload = true;
      dataset.window = window;
      const analyticsEngine = new AnalyticsEngine('web-tool-trim');
      analyticsEngine.loadDataSet(dataset);

      // get a signed dataset and push that
      const trimmedUnsignedDatasetStr = await toDataSet(analyticsEngine.frameCache);
      const trimmedUnsignedDataset = JSON.parse(trimmedUnsignedDatasetStr);
      trimmedUnsignedDataset.mobileAppId = localStorage.getItem('mobileAppId');
      const trimmedAndSignedDataset = {
        dataset: trimmedUnsignedDataset,
        signature: await cryptoUtils.signDataset(trimmedUnsignedDataset),
      };
      console.log('trimmed dataset', trimmedAndSignedDataset);

      await this.$API.createDataset({
        movementId: this.datasetMeta.movementId,
        dataset: trimmedAndSignedDataset,
      });

    },
  },
};
</script>
