<template>
  <div class="container-xxl m-1 p-2">
    <div class="row">
      <LoadedRecording />
    </div>

    <div class="row no-gutters">
      <div class="col-4">
        <PlaybackControls />
        <AlignmentControls />
        <AvatarCanvas />
      </div>
      <div class="col">
        <PlotsPanel
          :key="enabledAnalyticsKey0" 
          ref="plot" 
          :timeseries="timeseries"
          :timelineTags="timelineTags"
        />
        <AnalyticsSelector 
          @change="EnabledAnalyticsSetChanged" 
          :key="availableAnalyticsKey" 
          :timeseries="timeseries"
        />
        <DataTablePanel :key="enabledAnalyticsKey1" ref="datatable" :timeseries="timeseries" />
        <BLE />
        <SensorsTab />
      </div>
    </div>
  </div>
</template>

<script>
import LoadedRecording from "../common/loaded-recording.vue";
import BLE from "../ble/ble.vue";
import PlaybackControls from "./Playback.vue";
import AlignmentControls from "./Alignment.vue";
import AvatarCanvas from "../avatar/avatar.vue";
import SensorsTab from "../sensors/sensors.vue";
import PlotsPanel from '../PlotsPanel';
import DataTablePanel from '../DataTablePanel'
import AnalyticsSelector from '../AnalyticsSelector';


export default {
  components: {
    LoadedRecording,
    BLE,
    SensorsTab,
    PlaybackControls,
    AlignmentControls,
    AvatarCanvas,
    PlotsPanel,
    DataTablePanel,
    AnalyticsSelector
  },
  data() {
    return {
      availableAnalyticsKey: 0,
      enabledAnalyticsKey0: 10,
      enabledAnalyticsKey1: 20,
      timeseries: {
        data: [],
        fields: [],
      },
      timelineTags: [],
    };
  },
  mounted() {
    if (this.$route.query.datasetid === undefined) {
        if (this.$store.getters.dataSetLoaded.id) {
            this.$router.push({ path: `/vis?datasetid=${this.$store.getters.dataSetLoaded.id}` });
        } 
    } else {
      if (this.$route.query.datasetid.startsWith('local') === false && this.$route.query.datasetid !== this.$store.getters.dataSetLoaded.id) {
        console.log("datasetid in query does not match loaded dataset");
        this.loadFromServer(this.$route.query.datasetid);
      }
    }
    this.timeseries.fields = this.$AnalyticsEngine.timeseries.fields;
    //this.timeseries.data = this.$AnalyticsEngine.timeseries.getEnabled(this.timeseries.fields);
    this.timeseries.data = this.$AnalyticsEngine.timeseries.all;
    this.timelineTags = this.$AnalyticsEngine.frameCache.timelineTags;
    this.$refs.datatable.update();
    this.$refs.plot.update();
    this.analytics
    this.availableAnalyticsKey++;
    this.enabledAnalyticsKey0++;
    this.enabledAnalyticsKey1++;
  },
  methods: {
    loadFromServer(datasetid) {
      console.log("loadFromServer", datasetid);
      this.$router.push({ path: `/datasets?load=true&datasetid=${datasetid}` });
    },
    EnabledAnalyticsSetChanged(keysOfEnabledAnalytics) {
      this.timeseries.fields.map(field => {
        field.enabled = keysOfEnabledAnalytics.includes(field.key);
      });
      //this.timeseries.data = this.$AnalyticsEngine.timeseries.getEnabled(this.timeseries.fields);
      this.enabledAnalyticsKey1++;
      this.enabledAnalyticsKey0++;
    },
  },
  name: "Viz-Controls",
};
</script>
