<template>
  <div class="card">
    Profile Tags
    <TaggerWidget tagType="profile" :manage="true"/>
    Dataset Tags
    <TaggerWidget tagType="dataset" :manage="true"/>
  </div>
</template>

<script>
import TaggerWidget from "./common/Tagger.vue";


export default {
  name: 'AdminPage',
  components: {
    TaggerWidget,
  },
  data() {
    return {};
  },
}

</script>

