<template>
  <div>
    File: {{ currentFile }}
     <b-button style="font-size:smaller" class="m-1" v-on:click="pickFile">Load</b-button>
  </div>
</template>

<script>
export default {
  name: "FilePicker",
  props: ["currentFile", "appData"],
  data() {
    return {
    };
  },
  methods: {
    pickFile() {
      const input = document.createElement("input");
      input.type = "file";

      input.onchange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();
        reader.readAsText(file, "UTF-8");

        reader.onload = (readerEvent) => {
          console.log("FilePicker: file loaded", file.name);
          this.$AppData[this.appData] = {
            fileName: file.name,
            contents: readerEvent.target.result,
          };
          this.$emit(`filePicked`);
        };
      };

      input.click();
    },
  },
};
</script>
