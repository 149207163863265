<template>
  <div>
    <b-navbar type="dark" variant="dark">

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav v-if="userEmail">
          <b-nav-item>
            <router-link class="nav-link" to="/datasets">Datasets</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/vis">Visualization</router-link>
          </b-nav-item>
          <b-nav-item>
            <router-link class="nav-link" to="/user-info">User Info</router-link>
          </b-nav-item>
          <b-nav-item v-if="isAdmin">
            <router-link class="nav-link" to="/admin">Admin</router-link>
          </b-nav-item>
          <!-- <b-nav-item>
            <router-link class="nav-link" to="/video">Record/Playback Video</router-link>
          </b-nav-item> -->
        </b-navbar-nav>

        <b-navbar-nav class="ml-auto">
          <b-nav-item-dropdown right v-if="userEmail">
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>{{ userEmail }}</em>
            </template>
            <b-dropdown-item to="/user-info">Profile</b-dropdown-item>
            <b-dropdown-item @click="signOut">Sign Out</b-dropdown-item>
          </b-nav-item-dropdown>

          <b-nav-item>
          </b-nav-item>
          <b-nav-item-dropdown right>
            <!-- Using 'button-content' slot -->
            <template #button-content>
              <em>About Motusi-Web</em>
            </template>
            <b-dropdown-item>Web-App Version: {{ appVersion }}</b-dropdown-item>
            <b-dropdown-item>SharedJS Version: {{ libVersion }}</b-dropdown-item>
            <b-dropdown-item>Environment: {{ $Environment }}</b-dropdown-item>
          </b-nav-item-dropdown>

        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import {Auth} from '@aws-amplify/auth';
import AmplifyEventBus from "aws-amplify-vue/src/events/AmplifyEventBus";
import sharedjs from '@motusi/sharedjs/package.json';
import packagejs from '../../package.json';

export default {
  name: 'NavBar',
  data: () => {
    return {
      userEmail: undefined,
      appVersion: '0.0.0',
      libVersion: '0.0.0',
      isAdmin: false,
    }
  },
  async mounted() {
    AmplifyEventBus.$on('authState', state => {
      if (state === "signedOut") {
        this.userEmail = undefined;
      }
    });
  },
  async created() {
    this.appVersion = packagejs.version;
    this.libVersion = sharedjs.version;
    const user = await Auth.currentAuthenticatedUser();
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"] || [];
    this.isAdmin = groups.includes("MOTUSI_ADMIN");
    if (user) {
      this.userEmail = user.attributes.email;
    }
  },
  methods: {
    async signOut() {
      console.log('NavBar signout');
      await Auth.signOut();
      this.userEmail = undefined;
      this.$router.push({name: 'login'});
    }
  }
};
</script>

<style></style>
